// src/components/Carousel.js
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './carousel.css'; 
import d1 from '../../assets/z1.jpeg';
import d2 from '../../assets/z2.jpeg';
import d3 from '../../assets/z3.jpeg';
import d4 from '../../assets/z4.jpeg';
import d5 from '../../assets/z5.jpeg';

const images = [d1, d2, d3, d4, d5];

const CarouselComponent = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const carouselSettings = {
    responsive: responsive,
    autoPlay: true, 
    autoPlaySpeed: 2000, 
    infinite: true,
  };

  return (
    <Carousel {...carouselSettings}>
      {images.map((image, index) => (
        <div key={index} className="carousel-item">
          <img className='responsive-image' src={image} alt={`carousel-${index + 1}`} />
        </div>
      ))}
    </Carousel>
  );
};

export default CarouselComponent;
