
import React, { useState  } from 'react';
import './Navbar.css';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Grid,
  Drawer,
  List,
  ListItem,
} from '@mui/material';
import './Navbar.css';
import MenuIcon from '@mui/icons-material/Menu';
import Badge from '@mui/material/Badge';
import CloseIcon from '@mui/icons-material/Close';
import SupportIcon from '@mui/icons-material/Support';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo1.jpg'

const Navbar = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
  };



  return (
    <>
      {/* Mobile Header */}
      <AppBar  position='fixed' sx={{  background:'white', display: { md: 'none', sm: 'block' }}}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleSidebar}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon sx={{color:'black'}} />
          </IconButton>
          <Link to={`/`}>
            <img style={{background:'black' , borderRadius:'7px'}} src={logo} width='60px' alt='def1'/>
          </Link>
        </Toolbar>
      </AppBar>

      {/* Sidebar */}
      <Drawer
        anchor="left"
        open={isSidebarOpen}
        onClose={closeSidebar}
        PaperProps={{
          style: {
            width: '70%',
            background: 'white',
          },
        }}
      >
        <List>
          <ListItem onClick={closeSidebar} sx={{display:'flex' , justifyContent:'flex-end'}}>
            <CloseIcon />
          </ListItem>
          <ListItem onClick={closeSidebar}>
            <Link style={{textDecoration:'none' , fontWeight:'bold' , color:'black'}} to={`/`}>
              <Typography className="bold-text">Home</Typography>
            </Link>
          </ListItem>
          <ListItem onClick={closeSidebar}>
          <Link style={{textDecoration:'none' , fontWeight:'bold' , color:'black'}} to={`/menu`}>
            <Typography className="bold-text">Menu</Typography>
          </Link>
          </ListItem>
          <ListItem onClick={closeSidebar}>
          <Link style={{textDecoration:'none' , fontWeight:'bold' , color:'black'}} to={`/contact`}>
            <Typography className="bold-text">Contact Us</Typography>
          </Link>
          </ListItem>
          <ListItem onClick={closeSidebar}>
          <Link style={{textDecoration:'none' , fontWeight:'bold' , color:'black'}} to={`/offers`}>
            <Typography className="bold-text">Offers</Typography>
          </Link>
          </ListItem>
          {/* Add more menu items as needed */}
        </List>
      </Drawer>
      {/* Desktop Header */}
      <AppBar position='fixed' sx={{  background:'white',padding:'7px 0', display: { xs:'none', sm: 'none', md: 'block' } }}>
        <Toolbar>
          <Grid container alignItems="center" justifyContent='space-between' sx={{width:'80%' , margin:'0 auto' }} >
            <Grid item md={1.5}>
            <Link to={`/`}>
              <img style={{background:'black' , borderRadius:'7px'}} src={logo} width='100px' alt='def2' />
            </Link>
            <Typography sx={{color:'black' , fontWeight:'bold' , marginLeft:'7px'}}>Mahanagar</Typography>
            </Grid>
            <Grid  item md={7} >
            <Grid container justifyContent='space-evenly'>
              <Grid item>
                <Link to={`/`} style={{ textDecoration: 'none', color: 'inherit' }} >
                  <Typography sx={{ fontWeight: 'bold', cursor: 'pointer', color: 'black', '&:hover': { color: '#fd7e16' } }}>About</Typography>
                </Link>
              </Grid>
              <Grid item>
                <Link to={`/menu`} style={{ textDecoration: 'none', color: 'inherit' }} >
                  <Typography sx={{ fontWeight: 'bold', cursor: 'pointer', color: 'black', '&:hover': { color: '#fd7e16' } }}>Menu</Typography>
                </Link>
              </Grid>
              <Grid item>
                <Link to={`/contact`} style={{ textDecoration: 'none', color: 'inherit' }} >
                  <Typography sx={{ fontWeight: 'bold', cursor: 'pointer', color: 'black', '&:hover': { color: '#fd7e16' } }}>Contact Us</Typography>
                </Link>
              </Grid>
            </Grid>
            </Grid>
            <Grid item md={2.7} sx={{paddingLeft:'50px'}}>
             <Grid container direction='row' justifyContent='space-between'>
                {/* <Grid item md={6} sx={{display:'flex' , justifyContent:'center'}}>
                  <TextField size='small' id="outlined-basic" label="Search" variant="outlined" />
                </Grid> */}
                <Grid item md={6} sx={{display:'flex' , justifyContent:'center'}}>
                  <Grid container direction='row' justifyContent='space-evenly' alignItems='center'>
                    <Grid item>
                    <Link to={`/offers`} style={{ textDecoration: 'none', color: 'inherit' }} >
                      <Typography sx={{color:'black'}}>Offers</Typography>
                    </Link>
                    </Grid>
                    <Grid item>
                      <Badge  color="primary">
                        <SupportIcon sx={{color:'black'}} />
                      </Badge>
                    </Grid>
                  </Grid>
                </Grid>
             </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Navbar;