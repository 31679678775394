import { Grid, Typography } from "@mui/material";
import React from "react";

import banner from '../assets/ofban.jpeg';
import offer1 from '../assets/of1.jpeg';

const Offers = () => {
    return(
        <>
            <Grid container sx={{margin:'100px auto 40px'}}>
                <img src={banner} alt="aq" width='100%' />
            </Grid>
            <Grid container justifyContent='center' sx={{margin:'20px auto' ,width:'80%'}} spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={4} >
                    <img style={{borderRadius:'15px'}} src={offer1} alt="a1" width='90%' />
                </Grid>
                
            </Grid>
        </>
    )
}

export default Offers;