import React from 'react';
import { Grid, Typography  } from '@mui/material';
import CarouselComponent from '../components/homepage/carousel';
import '../App.css';
import b1 from '../assets/b1.jpeg';
import b2 from '../assets/b2.jpeg';
import { SocialIcon } from 'react-social-icons';


const Home = () => {


  return (
    <>
       <Grid>
        
        <Grid sx={{height:'50px'}}></Grid>
        <Grid sx={{padding:'50px 0px'}}>
          <CarouselComponent />
        </Grid>
        <Grid>
          {/* <Products /> */}
          <Grid container justifyContent='space-between' sx={{width:'80%' , margin:'0 auto'}}>
            <Grid item md={6} pt={3} pb={3}>
              <Typography className='typo1'>Welcome to The Grill Chill - Where Flavor Meets Harmony!</Typography>
              <Typography className='typo2'>At The Grill Chill, we take pride in offering a delightful dining experience where the rich aroma of
              sizzling grills and the cool vibes of chilled delights come together in perfect harmony. Our restaurant
              caters to both vegetarian and non-vegetarian palates, providing a diverse menu filled with
              mouthwatering dishes that are bound to satisfy every taste bud.
              </Typography>
            </Grid>
            <Grid item md={5}>
              <img  style={{borderRadius: '50px 15px'}} src={b1} width='100%' alt='b1' />
            </Grid>
          </Grid>
          <Grid container justifyContent='center' textAlign='center' sx={{width:'80%' , margin:'50px auto'}}>
            <Typography className='typo1'>Our Culinary Symphony:</Typography>
            <Typography className='typo2'>Embark on a culinary journey with our extensive menu featuring a plethora of scrumptious dishes.
            From succulent kebabs and hearty burgers to fresh salads and luscious desserts, each item on our
            menu is crafted with precision to ensure a burst of flavors in every bite. Our chefs are passionate
            about creating a diverse range of dishes that cater to all preferences, making The Grill Chill a haven
            for food enthusiasts.</Typography>
          </Grid>
          <Grid container sx={{width:'80%' , margin:'30px auto' , justifyContent:{xs:'center' , sm:'center' , md:'space-between'} }}>
            <Grid item md={3.6}>
              <div class="card first">
                  <div class="card__body">
                      <Typography className='card-typo'>Yummy Delights for Every Palate</Typography>
                  </div>
                  <span> Explore our menu and discover an array of yummy delights that showcase the essence of both
                vegetarian and non-vegetarian cuisines. Whether you're a meat lover or a veggie enthusiast, we
                have something special waiting for you. Our commitment to flavor and quality ensures that every
                dish is a culinary masterpiece that leaves you craving for more.</span>
              </div>
            </Grid>
            <Grid item md={3.6}>
            <div class="card second">
                <div class="card__body">
                    <Typography className='card-typo'>Hygiene at the Heart of Our Kitchen</Typography>
                </div>
                <span>We understand the importance of hygiene in the culinary world, and at The Grill Chill, cleanliness is
                our top priority. Our kitchen is a testament to the highest standards of cleanliness and food safety.
                We believe in serving not just delicious but also hygienic food, ensuring that your dining experience
                is not just satisfying but also worry-free.</span>
            </div>
            </Grid>
            <Grid item md={3.6}>
            <div class="card third">
                <div class="card__body">
                    <Typography className='card-typo'>Affordable Indulgence</Typography>
                </div>
                <span>Great food doesn't have to come with a hefty price tag. At The Grill Chill, we 
                believe in offering highquality, delectable dishes at an affordable cost. Our 
                mission is to make gourmet dining accessible to
                everyone, so you can savor the goodness without burning a hole in your pocket.</span>
            </div>
            </Grid>
          </Grid>
          <Grid container justifyContent='space-between' sx={{width:'80%' , margin:'60px auto'}}>
            <Grid item md={5}>
              <img style={{borderRadius: '15px 50px'}} src={b2} width='100%' alt='b1' />
            </Grid>
            <Grid item md={6}>
              <Typography className='typo1'>Our Team:</Typography>
              <Typography className='typo2'>Behind every delicious dish is a passionate team dedicated to creating an unforgettable dining
                experience. Our chefs, servers, and staff are committed to providing you with excellent service,
                ensuring that your time at The Grill Chill is nothing short of delightful.</Typography>
              <Typography style={{marginTop:'50px'}} className='typo1'>Visit Us:</Typography>
              <Typography className='typo2'>We invite you to step into The Grill Chill and experience a symphony of flavors, where each dish is a
                celebration of taste and quality. Join us for a memorable dining experience where deliciousness
                meets affordability, and hygiene is our utmost priority</Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent='center' textAlign='center' sx={{width:'80%' , margin:'50px auto'}}>
            <Typography className='typo3'>Indulge your senses at The Grill Chill - Because every meal is an adventure!</Typography>
          </Grid>
        </Grid>
        <div className="floating-icon">
          <SocialIcon url="https://wa.me/9044595859?text=Hi" network="whatsapp" target="_blank" />
        </div>
       </Grid>
       
    </>
  );
};

export default Home;