import React from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import './Footer.css';
import { SocialIcon } from 'react-social-icons';

function Footer() {

  const whatsappNumber = ' 9044595859';


  return (
    <>
    <Grid sx={{background:'#282828' , color:'white' , marginTop:'20px'}}>
      <Grid container direction='row' justifyContent='space-between' sx={{ width:'80%' , margin:'0px auto' , padding:'30px 0px 20px'}}>
        <Grid item md={3.9} sx={{marginTop:'25px'}}>
          {/* <img src='https://makanmate.com/wp-content/uploads/2022/09/logo.png' width='70px' alt='def3'/> */}
          <Typography sx={{margin:'10px 0'}}>We offer high-quality foods and the best delivery service, and the food market you can blindly trust.</Typography>
          <Grid container direction='row' sx={{marginTop:'10px'}} spacing={2}>
            <Grid item>
                <SocialIcon url="https://m.facebook.com/thegrillchill.mahanagar" network="facebook" target="_blank" />
            </Grid>
            <Grid item>
                <SocialIcon url="https://www.instagram.com/thegrillchillmahanagar?igsh=ZzNndm9kNmRtdDF5" network="instagram" target="_blank" />
            </Grid>
            <Grid item>
              {/* WhatsApp Icon with on click action */}
              <SocialIcon url="https://wa.me/9792693633?text=Hi" network="whatsapp" target="_blank" />
            </Grid>
            {/* <Grid item>
              <SocialIcon url="https://twitter.com/" network="twitter" target="_blank" />
            </Grid> */}
          </Grid>
          <Typography sx={{margin:'10px 0'}}> <span style={{fontWeight:'bold'}}>Address : </span>B 82, Sector - C, Mahanagar, Lucknow, Uttar Pradesh, 226006. 9792693633</Typography>
        </Grid>
        <Grid item md={2} sx={{marginTop:'25px'}}>
          <Typography sx={{fontWeight:'bold'}}>Our Information</Typography>
          <ul className='footerlist'>
            <li>News</li>
            <li>Privacy Policy</li>
            <li>Disclaimer</li>
            <li>Terms & Conditionss</li>
          </ul>
        </Grid>
      </Grid>
      <Divider variant="middle" sx={{borderBottomWidth: '5px', marginBottom:'20px'}} />
      </Grid>
    </>
  );
}

export default Footer;
