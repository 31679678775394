// src/components/Products.js
import React, { useState, useEffect } from 'react';
import './Products.css'; 
import {Grid , Typography , Popover , Box} from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
// import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ProductPage from '../products/categories';

const Products = () => {

  
  const [isSticky, setSticky] = useState(false);
  const [sortBy, setSortBy] = useState(null);
  const [vegActive , setVegActive ] = useState(false);
  const [priceActive , setPriceActive ] = useState(false);


  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;

      if (offset > 10) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleSortClick = (sortType) => {
    setSortBy(sortType);
    if(sortType === 'veg'){
      setVegActive(true);
    }else if(sortType === 'price'){
      setPriceActive(true);
    }
  };


  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  return (
    <div className="products-container">
      <header className={`products-header ${isSticky ? 'sticky' : ''}`}>
        <Grid container justifyContent="space-between">
            <Grid item md={4}>
                <Typography>CATEGORIES</Typography>
            </Grid>
            <Grid item md={1}>
                <Grid container direction='row' justifyContent='space-between'>
                    <Grid item>
                        <Typography 
                            sx={{
                            border: vegActive ? '1px solid green' : '1px solid grey' , 
                            padding:'2px 7px' , 
                            color: vegActive ? 'green' : 'grey' ,
                            fontSize:'13px',
                            borderRadius:'5px',
                            cursor:'pointer'
                            }}
                            onClick={() => handleSortClick('veg')}
                        >
                            VEG
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography 
                            sx={{
                            border: priceActive ? '1px solid green' : '1px solid grey' , 
                            padding:'2px 7px' , 
                            color: priceActive ? 'green' : 'grey' ,
                            fontSize:'13px',
                            cursor:'pointer',
                            borderRadius:'5px',
                            }}
                            onClick={() => handleSortClick('price')}
                        >
                            PRICE
                        </Typography>
                    </Grid>
                    {/* <Grid item sx={{display:'flex' , flexDirection:'row' , alignItems:'center', cursor:'pointer'}}>
                        <FilterAltOutlinedIcon />
                        <Typography sx={{fontSize:'14px'}} onClick={handleClick}>FILTERS</Typography>
                        
                    </Grid> */}
                    {/* <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <Box sx={{ padding: 2 }}>
                        <Typography>Filter 1</Typography>
                        <Typography>Filter 2</Typography>
                      </Box>
                    </Popover> */}
                </Grid>
            </Grid>
        </Grid>
      </header>
      <div className="products-content">
        <ProductPage sortBy={sortBy} />
      </div>
    </div>
  );
};

export default Products;
