import React, { useState } from 'react';
import { Grid, Typography, TextField, Button } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import banner from '../assets/contactbanner.jpeg';

const About = () => {


  const [sucessPop , SetSuccessPop] = useState(false);
  const [errorPop , SetErrorPop] = useState(false);
  const [buttonMsg , SetButtonMsg ] = useState("Submit");
  const [btnDisable , setButtonDisable] = useState(false);


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    SetSuccessPop(false);
  };



  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form fields
    if (!formData.name || !formData.email || !formData.phone || !formData.message) {
      // Handle validation error (you can show an error message)
      console.error('All fields are required');
      return;
    }

    // Prepare data for API request
    const requestData = {
      WebSiteUrl: window.location.href,
      FromEmail: 'appxpertmail@gmail.com',
      FromEmailPassword: 'qplldsxjcpqfypjm', 
      CCEmail: '',
      BCCEmail: '',
      EmailSubject: 'Grillchill Customer Query',
      EmailBody: `Message: ${formData.message}  , Email: ${formData.email} , Phone: ${formData.phone}`,
      Name: formData.name,
      ToEmail: 'dhanush.appxperts@gmail.com', 
      Phone: formData.phone,
      CreatedBy: 'User',
      CreatedOn: new Date().toISOString(),
      ChangedBy: 'Admin',
      ChangedOn: new Date().toISOString(),
      IsEmailSent: true,
    };

    // Send POST request to the API


    try {
      SetButtonMsg(<CircularProgress />);
      setButtonDisable(true);
      const response = await fetch('http://154.26.130.251:302/WebSiteSendEmail/SendEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        SetSuccessPop(true);
        SetButtonMsg("Submit");
        setButtonDisable(false);
        setFormData({
          name: '',
          email: '',
          phone: '',
          message: '',
        });
      } else {
        SetErrorPop(true);
        console.error('Failed to send email');
        setButtonDisable(false);
        SetButtonMsg("Submit");
      }
    } catch (error) {
      console.error('Error sending email', error);
      setButtonDisable(false);
      SetButtonMsg("Submit");
    }
  };

  return (
    <>
    <Grid container sx={{margin:'100px auto 40px'}}>
        <img src={banner} alt="aq" width='100%' />
    </Grid>
    <Grid container justifyContent='center' spacing={2} sx={{ width: { xs: '100%', sm: '100%', md: '50%' }, margin: '20px auto' }}>
      <Grid item xs={12}>
        <Typography variant="h4" align="center" sx={{ marginBottom: '20px' }}>
          Contact us
        </Typography>
      </Grid>
      <Grid item md={12} xs={12} sm={6}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={11}>
              <TextField
                fullWidth
                label="Name"
                variant="outlined"
                required
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={11}>
              <TextField
                fullWidth
                label="Email"
                type="email"
                variant="outlined"
                required
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={11}>
              <TextField
                fullWidth
                label="Phone"
                variant="outlined"
                required
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={11}>
              <TextField
                fullWidth
                label="Message"
                multiline
                rows={4}
                variant="outlined"
                required
                name="message"
                value={formData.message}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={11}>
              <Button type="submit" variant="contained" color="primary" fullWidth disabled={btnDisable}>
                {buttonMsg}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <Grid item xs={12} sm={6}>
        {/* Additional content can be added here */}
      </Grid>
      <Snackbar
        open={sucessPop}
        autoHideDuration={5000}
        onClose={handleClose}
        message="Thank you , We will get back to you !"
      />
      <Snackbar
        open={errorPop}
        autoHideDuration={5000}
        onClose={handleClose}
        message="Sorry , Something Went Wrong"
      />
    </Grid>
    </>
  );
};

export default About;
