import React, { useState, useRef } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import './categories.css'; 
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const ProductPage = ({sortBy}) => {


  const categories = [
    'Breakfast',
    'Beverages',
    'Mocktails',
    'Shakes',
    'Sandwich',
    'Soup',
    'Burgers',
    'Indian Pasta',
    'Noodles & Rice',
    'Indian Tandoori',
    'Pizza',
    'Momos',
    'Chinese Main Course',
    'Sabjiyan',
    'Chinese Starter',
    'Paneer Dishes',
    'Mini Meals',
    'Kofta and Aloo Dishes',
    'Dal Dishes',
    'Indian Main Course Non-Veg',
    'Eggs',
    'Bater Special',
    'Mutton In Gravy',
    'Breads',
    'Salads and Raita',
    'Rice and Biryani',
    'Fish Dishes',
    'Ice Creams',
    'Desserts',

  ];

//   const allProducts = [
//     {
//       category: 'Breakfast',
//       products: [
//           { id: 1,  name: 'Butter Toast ( 4 Pc )', price: '59', offer: '40', org: '100', type: 'veg' , img:'https://lh3.googleusercontent.com/T-y829XBOE8h308lHJaYaQdzzRX1RErizHroPvvBAGdFxybVyjusZAlTrCWxUt1yXqfA67DmrlpegAYdcGTxWv-M_xJD7YVQQw1exj4=w512-rw' },
//           { id: 2,  name: 'Aloo Paratha (1 Pc)', price: '49', offer: '40', org: '100', type: 'veg' , img:'https://lh3.googleusercontent.com/ixXPhRZ6Ce4_oQST2r15wT4jvzjPkX5HDTED7YaJJ_7OFFU0QFzJuV-_s19cwEZs1X7gi4MejWf3gaceF3mxMe_jCiMUxOZhdnX8AfTE=w512-rw' },
//           { id: 3,  name: 'Paneer Paratha (1 Pc)', price: '59', offer: '40', org: '100', type: 'veg' , img:'https://www.viniscookbook.com/wp-content/uploads/2018/09/20180924_224755.jpg' },
//           { id: 4,  name: 'Pyaaz Pakoda (8 Pc)', price: '69', offer: '40', org: '100', type: 'veg' , img:'https://www.indianhealthyrecipes.com/wp-content/uploads/2021/07/onion-pakoda-pakora-500x375.jpg' },
//           { id: 5,  name: 'Paneer Pakoda ( 6 Pc)', price: '89', offer: '40', org: '100', type: 'veg' , img:'https://www.indianhealthyrecipes.com/wp-content/uploads/2016/06/paneer-pakoda.jpg' },
//           { id: 6,  name: 'Veg Cutlet ( 4 Pc )', price: '89', offer: '40', org: '100', type: 'veg' , img:'https://cdn.cdnparenting.com/articles/2020/03/07132833/328074536.webp' },
//           { id: 7,  name: 'Poha', price: '59', offer: '59', org: '100', type: 'veg' , img:'https://media.istockphoto.com/id/670526200/photo/poha-indian-snacks.jpg?s=612x612&w=0&k=20&c=iEVKSfRzmcHxPIz1fKQBNzmcRpLbseh5vjXaRVckRZA=' },
//           { id: 8,  name: 'Poori Bhaji (4 Pc)', price: '69', offer: '40', org: '100', type: 'veg' , img:'https://cdn.cdnparenting.com/articles/2020/02/26144721/PURI-BHAJI-RECIPE.webp' },
//           { id: 9,  name: 'Pav Bhaji ( 4 Pc )', price: '79', offer: '40', org: '100', type: 'veg' , img:'https://e1.pxfuel.com/desktop-wallpaper/196/259/desktop-wallpaper-17-best-pav-bhaji-places-in-mumbai-in-curlytales.jpg' },
//           { id: 10, name: 'Omelette ( 2 Pc )', price: '49', offer: '40', org: '100', type: 'non-veg' , img:'https://w0.peakpx.com/wallpaper/619/437/HD-wallpaper-food-omelette.jpg' },
//           { id: 11, name: 'Bread Omelette ( 2 Pc )', price: '69', offer: '40', org: '100', type: 'non-veg' , img:'https://i.pinimg.com/736x/27/27/fb/2727fbef997cedbd2aa3e1e4c576f32d.jpg' },
//           { id: 12, name: 'Half Fry Omelette ( 2 Pc )', price: '49', offer: '40', org: '100', type: 'non-veg' , img:'https://i.ytimg.com/vi/knXqJcpqaCU/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLBwiIKjWHjdA3BDvpqc8jnWJhqxhA' },
//           { id: 13, name: 'Maggi', price: '29', offer: '40', org: '100', type: 'veg' , img:'https://i.pinimg.com/originals/71/d6/db/71d6db959ed3f8e47e7ef8ccbf74ea7f.jpg' },
//           { id: 14, name: 'Vegitable Maggi', price: '39', offer: '40', org: '100', type: 'veg' , img:'https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=1080/assets/search/usecase/vegetable_maggi_zdish.png' },
//           { id: 15, name: 'Egg Maggi', price: '49', offer: '40', org: '100', type: 'non-veg' , img:'https://www.licious.in/blog/wp-content/uploads/2020/12/Egg-Maggi.jpg' },
       
//       ],
//     },
//     {
//       category: 'Beverages',
//       products: [
//           {  id: 1, name: 'Cappuccino', price: '60', offer: '15', org: '55', type: 'veg' , img:'https://media.istockphoto.com/id/505168330/photo/cup-of-cafe-latte-with-coffee-beans-and-cinnamon-sticks.jpg?s=612x612&w=0&k=20&c=h7v8kAfWOpRapv6hrDwmmB54DqrGQWxlhP_mTeqTQPA=' },
//           {  id: 2, name: 'Drinking Water', price: 'MRP', offer: '', org: '', type: 'veg' , img:'https://e1.pxfuel.com/desktop-wallpaper/47/681/desktop-wallpaper-best-4-mineral-water-on-hip-drinking-water.jpg' },
//           {  id: 3, name: 'Cold Drink', price: 'MRP', offer: '', org: '', type: 'veg' , img:'https://e1.pxfuel.com/desktop-wallpaper/47/681/desktop-wallpaper-best-4-mineral-water-on-hip-drinking-water.jpg' },
//           {  id: 4, name: 'Butter Milk', price: '60', offer: '10', org: '65', type: 'veg' , img:'https://t3.ftcdn.net/jpg/04/94/26/98/360_F_494269829_Plv0Cdy4U0xTEObr0AdmNngquQmTg75v.jpg' },
//           {  id: 5, name: 'Lassi', price: '89', offer: '5', org: '85', type: 'veg' , img:'https://media.istockphoto.com/id/1008799838/photo/image-of-a-glass-of-lassi-made-from-milk-curd.jpg?s=612x612&w=0&k=20&c=L--UXX-L2bsK6Jy-mEigONFj9QEJyvU8b77FJnBLpBg=' },
//           {  id: 6, name: 'Tea', price: '40', offer: '20', org: '45', type: 'veg' , img:'https://images6.alphacoders.com/329/329631.jpg' },
//       ],
//     },
//     {
//       category: 'Mocktails',
//       products:[
//         { id: 1, name: 'Fresh Lime Soda', price: '79', offer: '12', org: '70', type: 'veg' , img:'https://soufflebombay.com/wp-content/uploads/2017/06/Homemade-Lime-Soda.jpg' },
//         { id: 2, name: 'Masala Soda Lemonade', price: '99', offer: '8', org: '90', type: 'veg' , img:'https://foodtrails25.com/wp-content/uploads/2020/05/MAsala-Soda.jpeg' },
//         { id: 3, name: 'Blue Lagoon', price: '110', offer: '15', org: '95', type: 'veg' , img:'https://img-global.cpcdn.com/recipes/6b5756fea006ff56/400x400cq70/photo.jpg' },
//         { id: 4, name: 'Virgin Mojito', price: '110', offer: '10', org: '100', type: 'veg' , img:'https://d3cm4d6rq8ed33.cloudfront.net/media/navpravartakfiles/19/e2bc7544-5d79-443b-9bd0-1611d6b90b82.jpg' },
//         { id: 5, name: 'Strawberry Mojito', price: '110', offer: '7', org: '105', type: 'veg' , img:'https://t3.ftcdn.net/jpg/01/79/28/38/360_F_179283853_wy0INSO5QHGENOK6J8ovaD8aGysKe9ZR.jpg' },
//         { id: 6, name: 'Watermelon Mojito', price: '110', offer: '20', org: '80', type: 'veg' , img:'https://oldtennesseedistillingco.com/wp-content/uploads/2020/08/watermelon-mojito.jpeg' },
//       ]
//     },
//     {
//       category: 'Shakes',
//       products: [
//           { id: 1, name: 'Cold Coffee', price: '110', offer: '10', org: '100', type: 'veg' , img:'https://images7.alphacoders.com/132/1323971.png' },
//           { id: 2, name: 'Cold Coffee With Ice Cream', price: '120', offer: '15', org: '110', type: 'veg' , img:'https://www.mrcoconut.in/img/products/22_10_2021_15_50_909_pm.webp' },
//           { id: 3, name: 'Vanilla/Strawberry Shake', price: '120', offer: '10', org: '110', type: 'veg' , img:'https://nadialim.com/wp-content/uploads/2020/09/Strawberry-vanilla-smoothie.jpg' },
//           { id: 4, name: 'Chocolate Shake', price: '130', offer: '12', org: '115', type: 'veg' , img:'https://img.freepik.com/free-photo/gourmet-dark-chocolate-milkshake-with-marshmallow-generative-ai_188544-12363.jpg' },
//           { id: 5, name: 'Oreo Shake', price: '130', offer: '8', org: '120', type: 'veg' , img:'https://t3.ftcdn.net/jpg/05/32/01/40/360_F_532014030_SIKl4Vkabl5SL1MZaF14UP8nELZNmPBk.jpg' },
//           { id: 6, name: 'Butterscotch Shake', price: '130', offer: '10', org: '118', type: 'veg' , img:'https://i0.wp.com/thebakernest.com/wp-content/uploads/2021/06/butterscotch-shake.jpg?fit=10002C1000&ssl=1' },
//           { id: 7, name: 'Mango Shake', price: '120', offer: '7', org: '112', type: 'veg' , img:'https://www.indianhealthyrecipes.com/wp-content/uploads/2021/04/mango-milkshake-recipe.jpg' },
//           { id: 8, name: 'Kesar Pista Shake', price: '139', offer: '12', org: '125', type: 'veg' , img:'https://img-global.cpcdn.com/recipes/4016d5b966a1b23e/680x482cq70/kesar-badam-pista-milkshake-recipe-main-photo.jpg'}
//       ],
//     },
//     {
//       category: 'Sandwich',
//       products: [
//         { id: 1, name: 'Veg Club Sandwich', price: '110', offer: '15', org: '130', type: 'veg' , img:'https://www.indianhealthyrecipes.com/wp-content/uploads/2019/05/club-sandwich-recipe.jpg' },
//         { id: 2, name: 'Grilled Sandwich', price: '99', offer: '10', org: '110', type: 'veg' ,img:'https://www.vegrecipesofindia.com/wp-content/uploads/2014/01/grilled-sandwich-4.jpg' },
//         { id: 3, name: 'Paneer Tikka Sandwich', price: '120', offer: '12', org: '135', type: 'veg' , img:'https://img-global.cpcdn.com/recipes/03154065feedc1d3/1200x630cq70/photo.jpg' },
//         { id: 4, name: 'Cheese Corn Sandwich', price: '110', offer: '8', org: '120', type: 'veg' , img:'https://static.toiimg.com/thumb/57853896.cms?imgsize=1542881&width=800&height=800' },
//         { id: 5, name: 'Chicken Tikka Sandwich', price: '130', offer: '15', org: '150', type: 'non-veg' ,img:'https://i.ytimg.com/vi/0Vj0Bl8QNjk/maxresdefault.jpg' },
//         { id: 6, name: 'Veg Club Sandwich', price: '130', offer: '10', org: '145', type: 'non-veg' , img:'https://kabilarestro.com/wp-content/uploads/2023/05/Non-Veg-club-Sandwich.jpg' },
//         { id: 7, name: 'Grilled Sandwich', price: '120', offer: '12', org: '135', type: 'non-veg' , img:'https://images.unsplash.com/photo-1528736235302-52922df5c122?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8Z3JpbGxlZCUyMHNhbmR3aWNofGVufDB8fDB8fHww' },
//         { id: 8, name: 'Paneer Tikka Sandwich', price: '130', offer: '15', org: '150', type: 'non-veg' , img:'https://static.toiimg.com/thumb/60043279.cms?width=1200&height=900' },
//         { id: 9, name: 'Cheese Corn Sandwich', price: '140', offer: '18', org: '170', type: 'non-veg' , img:'https://www.vegrecipesofindia.com/wp-content/uploads/2013/07/corn-sandwich-recipe-01a.jpg' },
//       ]
//     },
//     {
//       category: 'Soup',
//       products:[
//         { id: 1,  name: 'Cream Of Tomato Soup', price: '110', offer: '10', org: '95', type: 'veg' , img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwRIK1veP3te5K9P3QlJRWtal6muuxVSyhHBte0YHz3g&s' },
//         { id: 2,  name: 'Hot & Sour Soup', price: '110', offer: '12', org: '98', type: 'veg' , img:'https://aartimadan.com/wp-content/uploads/2020/11/hot-and-sour-soup.jpg' },
//         { id: 3,  name: 'Sweet Corn Soup', price: '110', offer: '8', org: '100', type: 'veg' , img:'https://www.bigbasket.com/media/uploads/recipe/w-l/1132_1.jpg' },
//         { id: 4,  name: 'Peaking Soup', price: '110', offer: '15', org: '90', type: 'veg' , img:'https://media.istockphoto.com/id/673531068/photo/hot-and-sour-soup.jpg?s=612x612&w=0&k=20&c=qd_Fro8GIZcLF8nO9XoAkcKAKehQBu061c7ZH_ikks0=' },
//         { id: 5,  name: 'Manchow Soup', price: '115', offer: '10', org: '105', type: 'veg' , img:'https://www.chefkunalkapur.com/wp-content/uploads/2021/03/Veg-Manchow-Soup-scaled.jpeg?v=1621618246' },
//         { id: 6,  name: 'Clear Soup', price: '110', offer: '12', org: '98', type: 'veg' , img:'https://i.ytimg.com/vi/KjxCEm-pD2k/maxresdefault.jpg' },
//         { id: 7,  name: 'Cream Of Mushroom Soup', price: '115', offer: '8', org: '110', type: 'veg' , img:'https://static.toiimg.com/photo/75454410.cms' },
//         { id: 8,  name: 'Lemon Coriander Soup', price: '110', offer: '15', org: '93', type: 'veg' , img:'https://i0.wp.com/dishesandflavors.com/wp-content/uploads/2021/08/Lemon-Coriander-Soup.jpg?fit=51842C3456&ssl=1' },
//         { id: 9,  name: 'Hot & Sour Soup', price: '120', offer: '10', org: '110', type: 'non-veg' , img:'https://aartimadan.com/wp-content/uploads/2020/11/hot-and-sour-soup.jpg' },
//         { id: 10, name: 'Sweet Corn Soup', price: '120', offer: '12', org: '105', type: 'non-veg' , img:'https://indianheartbeat.in/wp-content/uploads/2023/01/sweet-corn-soup.jpg' },
//         { id: 11, name: 'Peaking Soup', price: '120', offer: '8', org: '118', type: 'non-veg' , img:'https://cdn.sanity.io/images/2r0kdewr/production/fce2ea8994e36974df87ca6aeda7228833e6fc26-6000x3502.jpg?w=600&q=50' },
//         { id: 12, name: 'Manchow Soup', price: '125', offer: '15', org: '105', type: 'non-veg' , img:'https://c.ndtvimg.com/2020-07/k3990ffo_soup_625x300_24_July_20.jpg' },
//         { id: 13, name: 'Clear Soup', price: '125', offer: '10', org: '120', type: 'non-veg' , img:'https://yummyies.com/wp-content/uploads/2021/04/chicken-clear.jpg' },
//         { id: 14, name: 'Cream Of Mushrom Soup', price: '125', offer: '12', org: '112', type: 'non-veg' , img:'https://theveglife.com/wp-content/uploads/2014/10/Cream-of-Mushroom-Soup-Close-Up.jpg' },
//       ]
//     },
//     {
//       category: 'Burgers',
//       products:[
//         { id: 1, name: 'Veg Burger With Cheese', price: '110', offer: '10', org: '100', type: 'veg' , img:'https://static.toiimg.com/thumb/55201507.cms?width=1200&height=900' },
//         { id: 2, name: 'Paneer Tikka Burger', price: '120', offer: '15', org: '110', type: 'veg' , img:'https://coox-new.s3.ap-south-1.amazonaws.com/images/d/dishes/Paneer%20Tikka%20Burger-undefined-dish-img.jpeg?v=1627296847405' },
//         { id: 3, name: 'Chicken Burger', price: '130', offer: '12', org: '118', type: 'non-veg' , img:'https://coox-new.s3.ap-south-1.amazonaws.com/images/d/dishes/Paneer%20Tikka%20Burger-undefined-dish-img.jpeg?v=1627296847405' },
//         { id: 4, name: 'Chilli Chicken Burger', price: '130', offer: '8', org: '120', type: 'non-veg' , img:'https://realfood.tesco.com/media/images/RFO-1400x919-SpicyChickenBurger-98fc9913-fd2d-4559-b047-086ff4871a01-0-1400x919.jpg' },
//         { id: 5, name: 'Chicken Tikka Burger', price: '130', offer: '10', org: '118', type: 'non-veg' , img:'https://d3gy1em549lxx2.cloudfront.net/854cb359-11d2-4a8d-a4d4-bafc06b6dd7c.jpg' },
//         { id: 6, name: 'Jumbo Burger', price: '210', offer: '20', org: '168', type: 'non-veg' , img:'https://upload.wikimedia.org/wikipedia/commons/7/78/Jumbo_Burger.jpg' },
//       ]
//     },
//     {
//       category: 'Indian Pasta',
//       products: [
//         { id: 1, name: 'Arrabbita (Red Sauce Pasta - Veg)', price: '169', offer: '15', org: '200', type: 'veg' ,img:'https://vaya.in/recipes/wp-content/uploads/2018/02/Vegetarian-Penne-Arrabiata-Pasta.jpg' },
//         { id: 2, name: 'Alfredo (White Sauce Pasta - Veg)', price: '169', offer: '10', org: '190', type: 'veg' , img:'https://i.ytimg.com/vi/Y9ffxQ3ZieM/maxresdefault.jpg' },
//         { id: 3, name: 'Mix Sauce Pasta - Veg', price: '179', offer: '20', org: '225', type: 'veg' , img:'https://static.toiimg.com/photo/83107712.cms' },
//         { id: 4, name: 'Arrabbita (Red Sauce Pasta - Non-Veg)', price: '189', offer: '18', org: '230', type: 'non-veg' , img:'https://www.recipetineats.com/wp-content/uploads/2023/10/Penne-Arrabbiata-4.jpg' },
//         { id: 5, name: 'Alfredo (White Sauce Pasta - Non-Veg)', price: '189', offer: '12', org: '215', type: 'non-veg' , img:'https://www.archanaskitchen.com/images/archanaskitchen/0-Archanas-Kitchen-Recipes/2018/Penne_Pasta_Recipe_In_Alfredo_Sauce_With_Roasted_Mushrooms-1.jpg' },
//         { id: 6, name: 'Mix Sauce Pasta - Non-Veg', price: '199', offer: '15', org: '235', type: 'non-veg' , img:'https://i.ytimg.com/vi/rE-nF2kNI-k/maxresdefault.jpg' },
//       ]
//     },
//     {
//       category: 'Noodles & Rice',
//       products: [
//         { id: 1, name: 'Hakka Noodles (Veg)', price: '180', offer: '15', org: '210', type: 'veg' , img:'https://vegecravings.com/wp-content/uploads/2017/03/veg-hakka-noodles-recipe-with-step-by-step-instructions.jpg' },
//         { id: 2, name: 'Singapuri Noodles (Veg)', price: '180', offer: '10', org: '200', type: 'veg' , img:'https://cookingfromheart.com/wp-content/uploads/2019/11/Veg-Singapore-Noodles-1.jpg' },
//         { id: 3, name: 'Chowmein (Veg)', price: '180', offer: '20', org: '225', type: 'veg' , img:'https://i0.wp.com/dishesandflavors.com/wp-content/uploads/2021/01/Veg-Chow-Mein.jpg?fit=5184%2C3456&ssl=1' },
//         { id: 4, name: 'Chilli Garlic Noodles (Veg)', price: '180', offer: '18', org: '230', type: 'veg' , img:'https://cdn.tarladalal.com/members/9306/procstepimgs/chilli-garlic-noodles-(11)-11-188874.jpg' },
//         { id: 5, name: 'Fried Rice (Veg)', price: '180', offer: '12', org: '205', type: 'veg' , img:'https://www.indianveggiedelight.com/wp-content/uploads/2020/06/veg-fried-rice-featured.jpg' },
//         { id: 6, name: 'Egg Chowmein', price: '180', offer: '15', org: '250', type: 'non-veg' , img:'https://khadyaa.com/wp-content/uploads/2016/07/egg-chowmein-012-copy-600x600.jpg' },
//         { id: 7, name: 'Egg Fried Rice', price: '180', offer: '10', org: '230', type: 'non-veg' , img:'https://i0.wp.com/seonkyounglongest.com/wp-content/uploads/2020/02/Egg-Fried-Rice-2.jpg?fit=1300%2C867&ssl=1' },
//         { id: 8, name: 'American Chopsuey (Veg)', price: '230', offer: '15', org: '270', type: 'veg' , img:'https://www.vegrecipesofindia.com/wp-content/uploads/2017/11/veg-american-chopsuey-recipe-1.jpg' },
//         { id: 9, name: 'Shezwan Rice (Veg)', price: '180', offer: '12', org: '205', type: 'veg' , img:'https://content.jwplatform.com/thumbs/y7wvIS9T-720.jpg' },
//         { id: 10, name: 'Schezwan Noodles (Veg)', price: '180', offer: '15', org: '210', type: 'veg' , img:'https://images.squarespace-cdn.com/content/v1/5ec30182cff13b4331c5384d/c14fb1c8-7e2c-4ad3-9561-f3061ccc1e55/11111626-30C4-4406-BA90-168781AACD56.png?format=1000w' },
//         { id: 11, name: 'Paneer Fried Rice (Veg)', price: '190', offer: '18', org: '230', type: 'veg' , img:'https://www.indianhealthyrecipes.com/wp-content/uploads/2021/07/paneer-fried-rice-recipe.jpg' },
//         { id: 12, name: 'Paneer Chowmein (Veg)', price: '190', offer: '10', org: '210', type: 'veg' , img:'https://i.pinimg.com/736x/7e/b9/d4/7eb9d40c7aa9dfaa386a8c4a8655124e.jpg' },
//         { id: 14, name: 'Hakka Noodles (Non-Veg)', price: '210', offer: '20', org: '250', type: 'non-veg' , img:'https://www.whiskaffair.com/wp-content/uploads/2018/03/Chicken-Hakka-Noodles-2-3.jpg' },
//         { id: 15, name: 'Singapuri Noodles (Non-Veg)', price: '210', offer: '18', org: '245', type: 'non-veg' , img:'https://www.slimmingeats.com/blog/wp-content/uploads/2012/11/singapore-noodles-25-2.jpg' },
//         { id: 16, name: 'Chowmein (Non-Veg)', price: '210', offer: '15', org: '235', type: 'non-veg' , img:'https://www.dokan.co.in/wp-content/uploads/2019/11/mix-chow-mein-01.jpg' },
//         { id: 17, name: 'Chilli Garlic Noodles (Non-Veg)', price: '210', offer: '10', org: '245', type: 'non-veg' , img:'https://hot-thai-kitchen.com/wp-content/uploads/2021/11/chili-garlic-noodles-blog.jpg' },
//         { id: 18, name: 'Fried Rice (Non-Veg)', price: '210', offer: '12', org: '250', type: 'non-veg' , img:'https://www.positivenewstrends.com/wp-content/uploads/2019/05/Chicken-Fried-Rice-Ready-1.png' },
//         { id: 21, name: 'American Chopsuey (Non-Veg)', price: '250', offer: '12', org: '240', type: 'non-veg'  , img:'https://www.vegrecipesofindia.com/wp-content/uploads/2017/11/american-chopsuey-recipe-1a.jpg'},
//         { id: 22, name: 'Shezwan Rice (Non-Veg)', price: '210', offer: '15', org: '250', type: 'non-veg' , img:'https://www.nangaihomemadefood.com/product-images/download.jpeg/1746481000000637025/600x600'},
//         { id: 23, name: 'Schezwan Noodles (Non-Veg)', price: '250', offer: '18', org: '305', type: 'non-veg' , img:'https://www.cookwithmanali.com/wp-content/uploads/2021/08/Schezwan-Noodles-500x500.jpg' },
//       ]
//     },
//     {
//       category: 'Indian Tandoori',
//       products: [
//         { id: 1, name: 'Paneer Tikka', price: '280', offer: '20', org: '350', type: 'veg' , img:'https://images.news18.com/ibnkhabar/uploads/2023/03/Amritsari-Paneer-Tikka.jpg' },
//         { id: 2, name: 'Paneer Malai Tikka', price: '299', offer: '15', org: '350', type: 'veg' , img:'https://i.ytimg.com/vi/yIDnDMe9Dbk/maxresdefault.jpg' },
//         { id: 3, name: 'Paneer Hariyali Tikka', price: '289', offer: '10', org: '320', type: 'veg' , img:'https://s3.amazonaws.com/appforest_uf/f1553077582027x256878919194545760/Hariyali%20Paneer%20Tikka.jpg' },
//         { id: 4, name: 'Paneer Kalimirch Tikka', price: '289', offer: '8', org: '315', type: 'veg' , img:'https://saffronchronicles.files.wordpress.com/2013/08/share2.jpeg' },
//         { id: 5, name: 'Hara Bhara Kebab', price: '280', offer: '12', org: '320', type: 'veg' , img:'https://cookingfromheart.com/wp-content/uploads/2017/06/Hara-Bhara-Kabab-2.jpg' },
//         { id: 6, name: 'Dahi Ke Kebab', price: '169', offer: '5', org: '180', type: 'veg' , img:'https://img-global.cpcdn.com/recipes/473c6a44c27af4cd/680x482cq70/dahi-kebab-recipe-main-photo.jpg' },
//         { id: 7, name: 'Veg Shami Kebab', price: '179', offer: '15', org: '210', type: 'veg' , img:'https://www.chefkunalkapur.com/wp-content/uploads/2023/07/veg-kebab-recipe-1300x731.jpg?v=1689827628' },
//         { id: 8, name: 'Tandoori Mushroom', price: '289', offer: '10', org: '320', type: 'veg' , img:'https://cookingfromheart.com/wp-content/uploads/2017/02/Tandoori-Mushroom-Tikka-5.jpg' },
//         { id: 9, name: 'Tandoori Soya Chaap', price: '189', offer: '7', org: '205', type: 'veg' , img:'https://staticimg.amarujala.com/assets/images/2021/09/15/750x506/tandoori-soya-chaap_1631702105.jpeg' },
//         { id: 10, name: 'Chatpati Soya Chaap', price: '189', offer: '8', org: '205', type: 'veg' , img:'https://img-global.cpcdn.com/recipes/525120bdae65dd9f/1200x630cq70/photo.jpg' },
//         { id: 11, name: 'Malai Soya Chaap', price: '199', offer: '12', org: '225', type: 'veg' , img:'https://images.saymedia-content.com/.image/t_share/MTc0NDI4MzE1MDk0ODg2MDIy/soya-chaap-recipe-malai-chaap-recipe-soyabean-in-a-creamy-sauce.jpg' },
//         { id: 12, name: 'Achari Soya Chaap', price: '189', offer: '5', org: '200', type: 'veg' , img:'https://lh3.googleusercontent.com/tPIGpP-kiRxm-xyD-SvrmwKRCTtOluLdotey35_lOpcH1HTOzNaw7sLsQsgZOyB5F3-b8CUVmAHgtRVhtNzFWeAMyfYNBxcVXMWCPaRf=w512-rw' },
//         { id: 13, name: 'Afgani Soya Chaap', price: '199', offer: '10', org: '220', type: 'veg', img:'https://nishamadhulika.com/imgpst/featured/soya_chaap_white_gravy.jpg' },
//         { id: 14, name: 'Tandoori Chicken (Half/Full)', price: '219/399', offer: '15', org: '260/470', type: 'non-veg' , img:'https://www.hotelwelkinresidency.com/wp-content/uploads/2017/12/Tandoori-Chicken.jpg' },
//         { id: 15, name: 'Chicken Fry With Bone (Half/Full)', price: '219/399', offer: '12', org: '250/450', type: 'non-veg' },
//         { id: 16, name: 'Chicken Tikka', price: '279', offer: '18', org: '550', type: 'non-veg' },
//         { id: 17, name: 'Chicken Malai Tikka', price: '289', offer: '20', org: '500', type: 'non-veg' },
//         { id: 18, name: 'Murg Pahadi Tikka', price: '279', offer: '10', org: '310', type: 'non-veg' },
//         { id: 19, name: 'Chicken Angara', price: '289', offer: '8', org: '315', type: 'non-veg' },
//         { id: 20, name: 'Murg Afgani Tikka', price: '279', offer: '12', org: '320', type: 'non-veg' },
//         { id: 21, name: 'Murg Kalimirch Tikka', price: '279', offer: '15', org: '330', type: 'non-veg' },
//         { id: 22, name: 'Murg Lehsuni Tikka', price: '279', offer: '10', org: '310', type: 'non-veg' },
//         { id: 23, name: 'Murg Patiyala Tikka', price: '279', offer: '8', org: '305', type: 'non-veg' },
//         { id: 24, name: 'Murg Achari Tikka', price: '279', offer: '5', org: '300', type: 'non-veg' },
//         { id: 25, name: 'Chicken Seek Kebab', price: '279', offer: '10', org: '320', type: 'non-veg' },
//         { id: 26, name: 'Mutton Seek Kebab', price: '289', offer: '5', org: '300', type: 'non-veg' },
//         { id: 27, name: 'Mutton Shami Kebab (4 Pc)', price: '170', offer: '8', org: '185', type: 'non-veg' },
//         { id: 28, name: 'Galawati Kebab (4 Pc)', price: '170', offer: '15', org: '650', type: 'non-veg' },
//         { id: 29, name: 'Special Veg Plater', price: '449', offer: '10', org: '480', type: 'veg' },
//         { id: 30, name: 'Khazana Plater Non Veg', price: '550', offer: '12', org: '450', type: 'non-veg' },
//         // Add more items as needed
//       ]
//     },
//     {
//       category: 'Pizza',
//       products: [
//         { id: 1, name: 'Garlic Bread With Cheese (4pc)', price: '120', offer: '10', org: '135', type: 'veg' },
//         { id: 2, name: 'Tomato Cheese Capsicum Onion Pizza', price: '169', offer: '15', org: '200', type: 'veg' },
//         { id: 3, name: 'Paneer Chilly Pizza', price: '179', offer: '12', org: '205', type: 'veg' },
//         { id: 4, name: 'Paneer Tikka Pizza', price: '199', offer: '18', org: '245', type: 'veg' },
//         { id: 5, name: 'Cheese Corn Pizza', price: '199', offer: '20', org: '250', type: 'veg' },
//         { id: 6, name: 'Chicken Chilli Pizza', price: '210', offer: '14', org: '245', type: 'non-veg' },
//         { id: 7, name: 'Chicken Tikka Pizza', price: '230', offer: '17', org: '275', type: 'non-veg' },
//         { id: 8, name: 'Margherita Pizza', price: '230', offer: '13', org: '265', type: 'veg' },
//         { id: 9, name: 'Pizza-E-Bahar', price: '250', offer: '16', org: '295', type: 'veg' },
//         // Add more pizzas as needed
//       ]
//     },
//     {
//       category: 'Momos',
//       products: [
//         { id: 1, name: 'Momos Delicious', price: '109', offer: '12', org: '125', type: 'veg' },
//         { id: 2, name: 'Steam Veg Momos', price: '119', offer: '15', org: '140', type: 'veg' },
//         { id: 3, name: 'Fry Veg Momos', price: '119', offer: '10', org: '130', type: 'veg' },
//         { id: 4, name: 'Tandoori Veg Momos', price: '119', offer: '8', org: '130', type: 'veg' },
//         { id: 5, name: 'Kurkure Veg Momos', price: '139', offer: '18', org: '170', type: 'veg' },
//         { id: 6, name: 'Steam Paneer Momos', price: '149', offer: '14', org: '175', type: 'veg' },
//         { id: 7, name: 'Fry Paneer Momos', price: '139', offer: '20', org: '175', type: 'veg' },
//         { id: 8, name: 'Tandoori Paneer Momos', price: '149', offer: '12', org: '170', type: 'veg' },
//         { id: 9, name: 'Paneer Kurkure Momos', price: '149', offer: '15', org: '175', type: 'veg' },
//         { id: 10, name: 'Steam Chicken Momos', price: '149', offer: '10', org: '165', type: 'non-veg' },
//         { id: 11, name: 'Fry Chicken Momos', price: '149', offer: '8', org: '160', type: 'non-veg' },
//         { id: 12, name: 'Tandoori Chicken Momos', price: '169', offer: '18', org: '205', type: 'non-veg' },
//         { id: 13, name: 'Kurkure Chicken Momos', price: '169', offer: '15', org: '200', type: 'non-veg' },
//         // Add more momos variations as needed
//       ]
//     },
//     {
//       category: 'Chinese Main Course',
//       products: [
//         { id: 1, name: 'Chilli Paneer Gravy', price: '280', offer: '15', org: '325', type: 'veg' },
//         { id: 2, name: 'Paneer Manchurian Gravy', price: '280', offer: '12', org: '320', type: 'veg' },
//         { id: 3, name: 'Mushroom Chilli Gravy', price: '280', offer: '10', org: '310', type: 'veg' },
//         { id: 4, name: 'Veg Manchurian Gravy', price: '260', offer: '18', org: '315', type: 'veg' },
//         { id: 5, name: 'Chicken Chilli Gravy', price: '280', offer: '20', org: '350', type: 'non-veg' },
//         { id: 6, name: 'Chicken Manchurian Gravy', price: '280', offer: '15', org: '325', type: 'non-veg' },
//         // Add more Chinese main course dishes as needed
//       ]
//     },
//     {
//       category: 'Sabjiyan',
//       products: [
//         { id: 1, name: 'Mushroom Kaleji (Our Special)', price: '290', offer: '18', org: '355', type: 'veg' },
//         { id: 2, name: 'Mushroom Matar Masala', price: '280', offer: '15', org: '325', type: 'veg' },
//         { id: 3, name: 'Mushroom Masala', price: '280', offer: '12', org: '320', type: 'veg' },
//         { id: 4, name: 'Mushroom Paneer Masala', price: '280', offer: '10', org: '310', type: 'veg' },
//         { id: 5, name: 'Mushroom Do Pyaza', price: '280', offer: '20', org: '350', type: 'veg' },
//         { id: 6, name: 'Diwani Handi', price: '250', offer: '18', org: '305', type: 'veg' },
//         { id: 7, name: 'Mix Veg', price: '190', offer: '15', org: '225', type: 'veg' },
//         { id: 8, name: 'Aloo Jeera', price: '150', offer: '12', org: '170', type: 'veg' },
//         { id: 9, name: 'Aloo Gobhi Matar', price: '160', offer: '10', org: '180', type: 'veg' },
//         { id: 10, name: 'Baby Corn Masala', price: '250', offer: '20', org: '310', type: 'veg' },
//         { id: 11, name: 'Veg Jhalfrezi', price: '199', offer: '15', org: '235', type: 'veg' },
//         // Add more vegetarian dishes as needed
//       ]
//     },
//     {
//       category: 'Chinese Starter',
//       products: [
//         { id: 1, name: 'Chilli Paneer Dry', price: '280', offer: '15', org: '325', type: 'veg' },
//         { id: 2, name: 'Paneer 65', price: '280', offer: '12', org: '320', type: 'veg' },
//         { id: 3, name: 'Paneer Manchurian', price: '280', offer: '10', org: '310', type: 'veg' },
//         { id: 4, name: 'Mushroom Chilli Dry', price: '280', offer: '20', org: '350', type: 'veg' },
//         { id: 5, name: 'Veg Manchurian Dry', price: '260', offer: '18', org: '315', type: 'veg' },
//         { id: 6, name: 'Crispy Chilli Potato', price: '170', offer: '15', org: '200', type: 'veg' },
//         { id: 7, name: 'Veg Spring Roll', price: '160', offer: '12', org: '180', type: 'veg' },
//         { id: 8, name: 'Veg Salt & Pepper', price: '180', offer: '10', org: '200', type: 'veg' },
//         { id: 9, name: 'Crispy Chilly Baby Corn', price: '250', offer: '20', org: '310', type: 'veg' },
//         { id: 10, name: 'Crispy Chilly Mushroom', price: '280', offer: '15', org: '325', type: 'veg' },
//         { id: 11, name: 'French Fries', price: '160', offer: '12', org: '180', type: 'veg' },
//         { id: 12, name: 'Fried Crispy Corn', price: '250', offer: '18', org: '305', type: 'veg' },
//         { id: 13, name: 'Honey Chilli Potato', price: '199', offer: '15', org: '235', type: 'veg' },
//         { id: 14, name: 'Chicken Spring Roll', price: '169', offer: '12', org: '190', type: 'non-veg' },
//         { id: 15, name: 'Chicken 65', price: '180', offer: '10', org: '200', type: 'non-veg' },
//         { id: 16, name: 'Chilli Chicken Dry', price: '180', offer: '15', org: '210', type: 'non-veg' },
//         { id: 17, name: 'Crispy Fried Chicken', price: '180', offer: '20', org: '225', type: 'non-veg' },
//         { id: 18, name: 'Chicken Manchurian Dry', price: '180', offer: '18', org: '220', type: 'non-veg' },
//         { id: 19, name: 'Crunchy Chilli Dry', price: '290', offer: '12', org: '330', type: 'non-veg' },
//         { id: 20, name: 'Lemon Chicken', price: '280', offer: '15', org: '325', type: 'non-veg' },
//         { id: 21, name: 'Drums Of Heaven (6 Pc)', price: '299', offer: '10', org: '330', type: 'non-veg' },
//         { id: 22, name: 'Chicken Lollipop (6 Pc)', price: '299', offer: '15', org: '350', type: 'non-veg' },
//         // Add more Chinese starters as needed
//       ]
//     },
//     {
//       category: 'Paneer Dishes',
//       products: [
//         { id: 1, name: 'Handi Paneer', price: '280', offer: '15', org: '325', type: 'veg' },
//         { id: 2, name: 'Kadai Paneer', price: '280', offer: '12', org: '320', type: 'veg' },
//         { id: 3, name: 'Paneer Lababdar', price: '290', offer: '10', org: '320', type: 'veg' },
//         { id: 4, name: 'Paneer Do Pyaza', price: '280', offer: '20', org: '350', type: 'veg' },
//         { id: 5, name: 'Shahi Paneer', price: '280', offer: '15', org: '325', type: 'veg' },
//         { id: 6, name: 'Paneer Mirch Masala', price: '280', offer: '12', org: '320', type: 'veg' },
//         { id: 7, name: 'Matar Paneer', price: '280', offer: '10', org: '310', type: 'veg' },
//         { id: 8, name: 'Palak Paneer', price: '290', offer: '18', org: '355', type: 'veg' },
//         { id: 9, name: 'Paneer Butter Masala', price: '290', offer: '15', org: '340', type: 'veg' },
//         { id: 10, name: 'Paneer Peshwari', price: '280', offer: '20', org: '350', type: 'veg' },
//         { id: 11, name: 'Paneer Pasanda', price: '290', offer: '15', org: '340', type: 'veg' },
//         { id: 12, name: 'Paneer Bhurji', price: '280', offer: '12', org: '320', type: 'veg' },
//         { id: 13, name: 'Paneer Takatak', price: '290', offer: '10', org: '320', type: 'veg' },
//         { id: 14, name: 'Paneer Tawa Masala', price: '320', offer: '18', org: '390', type: 'veg' },
//         { id: 15, name: 'Paneer Tikka Masala', price: '320', offer: '15', org: '375', type: 'veg' },
//         { id: 16, name: 'Paneer Delicious', price: '320', offer: '12', org: '365', type: 'veg' },
//         // Add more Paneer dishes as needed
//       ]
//     },
//     {
//       category: 'Mini Meals',
//       products: [
//         { id: 1, name: 'Kadhai Paneer + Mini Lachha Paratha Mini Naan (2 Pc)', price: '199', offer: '10', org: '220', type: 'veg' },
//         { id: 2, name: 'Kadhai Paneer + Steam Rice / Jeera Rice', price: '199', offer: '15', org: '235', type: 'veg' },
//         { id: 3, name: 'Paneer Butter Masala + Mini Naan / Mini Luchha (2 Pc)', price: '199', offer: '12', org: '225', type: 'veg' },
//         { id: 4, name: 'Dal Tadka With Jeera Rice / Plain Rice / (2 Pc) Bread', price: '180', offer: '18', org: '220', type: 'veg' },
//         { id: 5, name: 'Dal Makhani With Jeera Rice / Plain Rice / (2Pc) Bread', price: '199', offer: '15', org: '235', type: 'veg' },
//         { id: 6, name: 'Paneer Chilly With Veg Noodle / Veg Fried Rice', price: '180', offer: '10', org: '200', type: 'veg' },
//         { id: 7, name: 'Chhola With Mini Naan / Mini Kulcha (2Pc)', price: '170', offer: '12', org: '195', type: 'veg' },
//         { id: 8, name: 'Veg Manchurian With Veg Fried Rice / Veg Noodle', price: '170', offer: '10', org: '190', type: 'veg' },
//         { id: 9, name: 'Paneer Handi With Mini Naan / Mini Lachha (2Pc)', price: '199', offer: '18', org: '245', type: 'veg' },
//         { id: 10, name: 'Paneer Tawa Masala With Mini Naan / Missi Roti (2 Pc)', price: '230', offer: '15', org: '270', type: 'veg' },
//         // Add more Mini Meals as needed
//       ]
//     },
//     {
//       category: 'Kofta and Aloo Dishes',
//       products: [
//         { id: 1, name: 'Veg Kofta', price: '270', offer: '15', org: '315', type: 'veg' },
//         { id: 2, name: 'Malai Kofta', price: '290', offer: '12', org: '330', type: 'veg' },
//         { id: 3, name: 'Nargisi Kofta', price: '280', offer: '10', org: '310', type: 'veg' },
//         { id: 4, name: 'Aloo Dum Banaras', price: '220', offer: '18', org: '270', type: 'veg' },
//         { id: 5, name: 'Aloo Dum Kashmiri', price: '230', offer: '15', org: '270', type: 'veg' },
//         { id: 6, name: 'Aloo Dum Lucknowi', price: '220', offer: '12', org: '250', type: 'veg' },
//         { id: 7, name: 'Kofta & Aloo', price: '220', offer: '10', org: '245', type: 'veg' },
//         // Add more Kofta and Aloo dishes as needed
//       ]
//     },
//     {
//       category: 'Dal Dishes',
//       products: [
//         { id: 1, name: 'Dal Fry', price: '169', offer: '12', org: '190', type: 'veg' },
//         { id: 2, name: 'Dal Tadka', price: '179', offer: '15', org: '210', type: 'veg' },
//         { id: 3, name: 'Dal Makhani', price: '199', offer: '10', org: '220', type: 'veg' },
//         { id: 4, name: 'Dal Punchmail', price: '179', offer: '18', org: '220', type: 'veg' },
//         { id: 5, name: 'Dal Bukhara', price: '179', offer: '15', org: '210', type: 'veg' },
//         { id: 6, name: 'Dal Special', price: '179', offer: '12', org: '205', type: 'veg' },
//         // Add more Dal dishes as needed
//       ]
//     },
//     {
//       category: 'Indian Main Course Non-Veg',
//       products: [
//         { id: 1, name: 'Chicken Masala (HALF)', price: '299/-', offer: '15', org: '350', type: 'non-veg' },
//         { id: 2, name: 'Chicken Masala (FULL)', price: '589/-', offer: '15', org: '690', type: 'non-veg' },
//         { id: 3, name: 'Butter Chicken (HALF)', price: '319/-', offer: '12', org: '365', type: 'non-veg' },
//         { id: 4, name: 'Butter Chicken (FULL)', price: '629/-', offer: '12', org: '720', type: 'non-veg' },
//         { id: 5, name: 'Chicken Korma (HALF)', price: '299/-', offer: '10', org: '330', type: 'non-veg' },
//         { id: 6, name: 'Chicken Korma (FULL)', price: '589/-', offer: '10', org: '650', type: 'non-veg' },
//         { id: 7, name: 'Chicken Peshwari (HALF)', price: '280/-', offer: '15', org: '320', type: 'non-veg' },
//         { id: 8, name: 'Chicken Peshwari (FULL)', price: '590/-', offer: '20', org: '720', type: 'non-veg' },
//         { id: 9, name: 'Chicken Kalimirch (HALF)', price: '310/-', offer: '18', org: '380', type: 'non-veg' },
//         { id: 10, name: 'Chicken Kalimirch (FULL)', price: '630/-', offer: '15', org: '750', type: 'non-veg' },
//         { id: 11, name: 'Rara Chicken (HALF)', price: '299/-', offer: '10', org: '330', type: 'non-veg' },
//         { id: 12, name: 'Rara Chicken (FULL)', price: '599/-', offer: '10', org: '660', type: 'non-veg' },
//         { id: 13, name: 'Chicken Do Pyaza (HALF)', price: '299/-', offer: '15', org: '350', type: 'non-veg' },
//         { id: 14, name: 'Chicken Do Pyaza (FULL)', price: '599/-', offer: '15', org: '710', type: 'non-veg' },
//         { id: 15, name: 'Murg Labadar (HALF)', price: '310/-', offer: '12', org: '355', type: 'non-veg' },
//         { id: 16, name: 'Murg Labadar (FULL)', price: '599/-', offer: '12', org: '680', type: 'non-veg' },
//         { id: 17, name: 'Chicken Korma (Awadhi) (HALF)', price: '289/-', offer: '10', org: '320', type: 'non-veg' },
//         { id: 18, name: 'Chicken Korma (Awadhi) (FULL)', price: '589/-', offer: '10', org: '650', type: 'non-veg' },
//         { id: 19, name: 'Chicken Achari Masala (HALF)', price: '289/-', offer: '15', org: '340', type: 'non-veg' },
//         { id: 20, name: 'Chicken Achari Masala (FULL)', price: '589/-', offer: '15', org: '690', type: 'non-veg' },
//         { id: 21, name: 'Chicken Dehati (HALF)', price: '289/-', offer: '12', org: '330', type: 'non-veg' },
//         { id: 22, name: 'Chicken Dehati (FULL)', price: '589/-', offer: '12', org: '670', type: 'non-veg' },
//         { id: 23, name: 'Chicken Kolhapuri (HALF)', price: '289/-', offer: '10', org: '320', type: 'non-veg' },
//         { id: 24, name: 'Chicken Kolhapuri (FULL)', price: '589/-', offer: '10', org: '650', type: 'non-veg' },
//         { id: 25, name: 'Chicken Stew (HALF)', price: '289/-', offer: '15', org: '340', type: 'non-veg' },
//         { id: 26, name: 'Chicken Stew (FULL)', price: '589/-', offer: '15', org: '690', type: 'non-veg' },
//         { id: 27, name: 'Chicken Bhuna Gust (HALF)', price: '289/-', offer: '12', org: '330', type: 'non-veg' },
//         { id: 28, name: 'Chicken Bhuna Gust (FULL)', price: '589/-', offer: '12', org: '670', type: 'non-veg' },
//         { id: 29, name: 'Chicken Champaran (HALF)', price: '289/-', offer: '10', org: '320', type: 'non-veg' },
//         { id: 30, name: 'Chicken Champaran (FULL)', price: '589/-', offer: '10', org: '650', type: 'non-veg' },
//         { id: 31, name: 'Kadaknath Chicken (HALF)', price: '600/-', offer: '18', org: '730', type: 'non-veg' },
//         { id: 32, name: 'Kadaknath Chicken (FULL)', price: '1050/-', offer: '15', org: '1230', type: 'non-veg' },
//         // Add the rest of the products here
//       ]
//     },
//     {
//       category: 'Eggs',
//       products: [
//         { id: 1, name: 'Egg Curry (2 pc)', price: '129/-', offer: '10%', org: '150/-', type: 'egg' },
//         { id: 2, name: 'Egg Masala (2 pc)', price: '129/-', offer: '8%', org: '140/-', type: 'egg' },
//         { id: 3, name: 'Egg Bhurji (2 pc)', price: 'N/A', offer: 'N/A', org: 'N/A', type: 'egg' },
//         { id: 4, name: 'Eggs (3 pc)', price: '199/-', offer: '15%', org: '230/-', type: 'egg' },
//         { id: 5, name: 'Eggs (4 pc)', price: '199/-', offer: '12%', org: '210/-', type: 'egg' },
//       ],
//     },
//     {
//       category: 'Bater Special',
//       products: [
//         { id: 1, name: 'Bater Masala (2 pc)', price: '380/-', offer: '18%', org: '450/-', type: 'non-veg' },
//         { id: 2, name: 'Bater Gravy (2 pc)', price: '380/-', offer: '20%', org: '480/-', type: 'non-veg' },
//         { id: 4, name: 'Bater Masala (4 pc)', price: '650/-', offer: '15%', org: '750/-', type: 'non-veg' },
//         { id: 5, name: 'Bater Gravy (4) pc)', price: '650/-', offer: '10%', org: '700/-', type: 'non-veg' },
//       ],
//     },
//     {
//       category: 'Mutton In Gravy',
//       products: [
//         { id: 1, name: 'Mutton Handi (HALF)', price: '380/-', offer: '25%', org: '500/-', type: 'non-veg' },
//         { id: 2, name: 'Mutton Handi (FULL)', price: '730/-', offer: '22%', org: '900/-', type: 'non-veg' },
//         { id: 3, name: 'Mutton Rogan Josh (HALF)', price: '380/-', offer: '20%', org: '480/-', type: 'non-veg' },
//         { id: 4, name: 'Mutton Rogan Josh (FULL)', price: '730/-', offer: '18%', org: '850/-', type: 'non-veg' },
//         { id: 5, name: 'Mutton Bhuna Gost (HALF)', price: '380/-', offer: '15%', org: '450/-', type: 'non-veg' },
//         { id: 6, name: 'Mutton Bhuna Gost (FULL)', price: '730/-', offer: '12%', org: '800/-', type: 'non-veg' },
//         { id: 7, name: 'Mutton Rara (HALF)', price: '380/-', offer: '10%', org: '420/-', type: 'non-veg' },
//         { id: 8, name: 'Mutton Rara (FULL)', price: '730/-', offer: '8%', org: '700/-', type: 'non-veg' },
//         { id: 9, name: 'Mutton Do Pyaza (HALF)', price: '380/-', offer: '12%', org: '450/-', type: 'non-veg' },
//         { id: 10, name: 'Mutton Do Pyaza (FULL)', price: '730/-', offer: '10%', org: '800/-', type: 'non-veg' },
//         { id: 11, name: 'Mutton Sag Wala (HALF)', price: '380/-', offer: '15%', org: '500/-', type: 'non-veg' },
//         { id: 12, name: 'Mutton Sag Wala (FULL)', price: '730/-', offer: '18%', org: '900/-', type: 'non-veg' },
//         { id: 13, name: 'Bhuna Keema (HALF)', price: '380/-', offer: '20%', org: '480/-', type: 'non-veg' },
//         { id: 14, name: 'Bhuna Keema (FULL)', price: '730/-', offer: '22%', org: '850/-', type: 'non-veg' },
//         { id: 15, name: 'Mutton Stew (HALF)', price: '380/-', offer: '25%', org: '500/-', type: 'non-veg' },
//         { id: 16, name: 'Mutton Stew (FULL)', price: '730/-', offer: '20%', org: '800/-', type: 'non-veg' },
//         { id: 17, name: 'Boti Kebab (HALF)', price: '390/-', offer: '15%', org: '450/-', type: 'non-veg' },
//         { id: 18, name: 'Boti Kebab (FULL)', price: '730/-', offer: '10%', org: '700/-', type: 'non-veg' },
//         { id: 19, name: 'Mutton Nehari (HALF)', price: '380/-', offer: '12%', org: '480/-', type: 'non-veg' },
//         { id: 20, name: 'Mutton Nehari (FULL)', price: '730/-', offer: '18%', org: '900/-', type: 'non-veg' },
//         { id: 21, name: 'Mutton Kaleji (HALF)', price: '380/-', offer: '20%', org: '480/-', type: 'non-veg' },
//         { id: 22, name: 'Mutton Kaleji (FULL)', price: '730/-', offer: '15%', org: '750/-', type: 'non-veg' },
//         { id: 23, name: 'Keema Kaleji (HALF)', price: '380/-', offer: '10%', org: '420/-', type: 'non-veg' },
//         { id: 24, name: 'Keema Kaleji (FULL)', price: '730/-', offer: '8%', org: '700/-', type: 'non-veg' },
//         { id: 25, name: 'Mutton Rizwala (HALF)', price: '380/-', offer: '12%', org: '450/-', type: 'non-veg' },
//         { id: 26, name: 'Mutton Rizwala (FULL)', price: '730/-', offer: '10%', org: '800/-', type: 'non-veg' },
//       ],
//     },
//     {
//       category: 'Breads',
//       products: [
//         { id: 1, name: 'Tawa Roti', price: '15', offer: '5', org: '20', type: 'veg', img: '' },
//         { id: 2, name: 'Tawa Roti Butter', price: '20', offer: '8', org: '22', type: 'veg', img: '' },
//         { id: 3, name: 'Tandoori Roti', price: '25', offer: '10', org: '28', type: 'veg', img: '' },
//         { id: 4, name: 'Tandoori Butter', price: '30', offer: '12', org: '34', type: 'veg', img: '' },
//         { id: 5, name: 'Rumali Roti', price: '15', offer: '7', org: '18', type: 'veg', img: '' },
//         { id: 6, name: 'Plain Naan', price: '40', offer: '15', org: '45', type: 'veg', img: '' },
//         { id: 7, name: 'Butter Naan', price: '50', offer: '20', org: '55', type: 'veg', img: '' },
//         { id: 8, name: 'Garlic Naan', price: '60', offer: '25', org: '65', type: 'veg', img: '' },
//         { id: 9, name: 'Lachha Paratha', price: '45', offer: '18', org: '55', type: 'veg', img: '' },
//         { id: 10, name: 'Missi Roti', price: '45', offer: '15', org: '50', type: 'veg', img: '' },
//         { id: 11, name: 'Onion Kulcha', price: '59', offer: '22', org: '60', type: 'veg', img: '' },
//         { id: 12, name: 'Paneer Kulcha', price: '89', offer: '8', org: '10', type: 'veg', img: '' },
//         // ... Add more items if needed
//       ],
//     },
//     {
//       category: 'Salads and Raita',
//       products: [
//         { id: 1, name: 'Boodi Raita', price: '80', offer: '10', org: '90', type: 'veg', img: '' },
//         { id: 2, name: 'Mix Raita', price: '90', offer: '15', org: '105', type: 'veg', img: '' },
//         { id: 3, name: 'Pineapple Raita', price: '99', offer: '12', org: '111', type: 'veg', img: '' },
//         { id: 4, name: 'Fruit Raita', price: '120', offer: '20', org: '140', type: 'veg', img: '' },
//         { id: 5, name: 'Plain Curd', price: '79', offer: '8', org: '87', type: 'veg', img: '' },
//         { id: 6, name: 'Green Salad', price: '79', offer: '10', org: '89', type: 'veg', img: '' },
//         { id: 7, name: 'Onion Salad', price: '59', offer: '7', org: '66', type: 'veg', img: '' },
//         { id: 8, name: 'Cucumber Salad', price: '69', offer: '9', org: '78', type: 'veg', img: '' },
//         { id: 9, name: 'Papad (2 Pc) Dry/Fry', price: '49', offer: '5', org: '54', type: 'veg', img: '' },
//         { id: 10, name: 'Masal Papad (2 Pc)', price: '70', offer: '12', org: '82', type: 'veg', img: '' },
//         // ... Add more items if needed
//       ],
//     },
//     {
//       category: 'Rice and Biryani',
//       products: [
//         { id: 1, name: 'Murg Dum Biryani (2pc)', price: '149', offer: '20', org: '169', type: 'non-veg', img: '' },
//         { id: 2, name: 'Mutton Dum Biryani (2pc)', price: '179', offer: '25', org: '204', type: 'non-veg', img: '' },
//         { id: 3, name: 'Egg Dum Biryani (2pc)', price: '139', offer: '18', org: '157', type: 'non-veg', img: '' },
//         { id: 4, name: 'Veg Dum Biryani', price: '149', offer: '15', org: '164', type: 'veg', img: '' },
//         { id: 5, name: 'Steam Rice', price: '120', offer: '10', org: '130', type: 'veg', img: '' },
//         { id: 6, name: 'Jeera Rice', price: '130', offer: '12', org: '142', type: 'veg', img: '' },
//         { id: 7, name: 'Veg Pulao', price: '139', offer: '14', org: '153', type: 'veg', img: '' },
//         { id: 8, name: 'Peas Pulao', price: '139', offer: '15', org: '154', type: 'veg', img: '' },
//         { id: 9, name: 'Paneer Pulao', price: '149', offer: '20', org: '169', type: 'veg', img: '' },
//         { id: 10, name: 'Kashmiri Pulao', price: '179', offer: '25', org: '204', type: 'veg', img: '' },
//         // ... Add more items if needed
//       ],
//     },
//     {
//       category: 'Fish Dishes',
//       products: [
//         { id: 1, name: 'Fish Tikka (8pc)', price: '280', offer: '15', org: '295', type: 'non-veg', img: '' },
//         { id: 2, name: 'Fish Fry (4pc)', price: '260', offer: '12', org: '272', type: 'non-veg', img: '' },
//         { id: 3, name: 'Fish Curry (4pc)', price: '280', offer: '18', org: '298', type: 'non-veg', img: '' },
//         { id: 4, name: 'Fish Finger', price: '260', offer: '10', org: '270', type: 'non-veg', img: '' },
//       ],
//     },
//     {
//       category: 'Ice Creams',
//       products: [
//         { id: 1, name: 'Vanilla Single', price: '49', offer: '5', org: '54', type: 'veg', img: '' },
//         { id: 2, name: 'Vanilla Double', price: '80', offer: '10', org: '90', type: 'veg', img: '' },
//         { id: 3, name: 'Strawberry Single', price: '59', offer: '7', org: '66', type: 'veg', img: '' },
//         { id: 4, name: 'Strawberry Double', price: '99', offer: '12', org: '111', type: 'veg', img: '' },
//         { id: 5, name: 'Mango Single', price: '59', offer: '8', org: '67', type: 'veg', img: '' },
//         { id: 6, name: 'Mango Double', price: '99', offer: '15', org: '114', type: 'veg', img: '' },
//         { id: 7, name: 'Chocolate Single', price: '65', offer: '6', org: '71', type: 'veg', img: '' },
//         { id: 8, name: 'Chocolate Double', price: '110', offer: '12', org: '122', type: 'veg', img: '' },
//         { id: 9, name: 'Butter Scotch Single', price: '65', offer: '7', org: '72', type: 'veg', img: '' },
//         { id: 10, name: 'Butter Scotch Double', price: '110', offer: '15', org: '125', type: 'veg', img: '' },
//         { id: 11, name: 'Kesar Pista Single', price: '65', offer: '8', org: '73', type: 'veg', img: '' },
//         { id: 12, name: 'Kesar Pista Double', price: '110', offer: '18', org: '128', type: 'veg', img: '' },
//         { id: 13, name: 'Ice-Cream Single', price: '65', offer: '10', org: '75', type: 'veg', img: '' },
//         { id: 14, name: 'Ice-Cream Double', price: '110', offer: '20', org: '130', type: 'veg', img: '' },
//         // ... Add more items if needed
//       ],
//     },
//     {
//       category: 'Desserts',
//       products: [
//         { id: 1, name: 'Gulab Jamun (1pc)', price: '30', offer: '5', org: '54', type: 'veg', img: '' },
//         { id: 2, name: 'Gulab Jamun (2pc)', price: '49', offer: '5', org: '54', type: 'veg', img: '' },
//         { id: 3, name: 'Rasgulla (1pc)', price: '30', offer: '5', org: '54', type: 'veg', img: '' },
//         { id: 4, name: 'Rasgulla (2pc)', price: '49', offer: '5', org: '54', type: 'veg', img: '' },
//         { id: 3, name: 'Kulhad Kheer', price: '49', offer: '7', org: '66', type: 'veg', img: '' },
//         { id: 5, name: 'Shahi Tukda', price: '80', offer: '8', org: '67', type: 'veg', img: '' },
//       ]
//     }
    
    

    
    
// ];


const allProducts = [
  {
    category: 'Breakfast',
    products: [
        { id: 1,  name: 'Butter Toast ( 4 Pc )', price: '59', offer: '40', org: '100', type: 'veg' , img:'https://lh3.googleusercontent.com/T-y829XBOE8h308lHJaYaQdzzRX1RErizHroPvvBAGdFxybVyjusZAlTrCWxUt1yXqfA67DmrlpegAYdcGTxWv-M_xJD7YVQQw1exj4=w512-rw' },
        { id: 2,  name: 'Aloo Paratha (1 Pc)', price: '49', offer: '40', org: '100', type: 'veg' , img:'https://lh3.googleusercontent.com/ixXPhRZ6Ce4_oQST2r15wT4jvzjPkX5HDTED7YaJJ_7OFFU0QFzJuV-_s19cwEZs1X7gi4MejWf3gaceF3mxMe_jCiMUxOZhdnX8AfTE=w512-rw' },
        { id: 3,  name: 'Paneer Paratha (1 Pc)', price: '59', offer: '40', org: '100', type: 'veg' , img:'https://www.viniscookbook.com/wp-content/uploads/2018/09/20180924_224755.jpg' },
        { id: 4,  name: 'Pyaaz Pakoda (8 Pc)', price: '69', offer: '40', org: '100', type: 'veg' , img:'https://www.indianhealthyrecipes.com/wp-content/uploads/2021/07/onion-pakoda-pakora-500x375.jpg' },
        { id: 5,  name: 'Paneer Pakoda ( 6 Pc)', price: '89', offer: '40', org: '100', type: 'veg' , img:'https://www.indianhealthyrecipes.com/wp-content/uploads/2016/06/paneer-pakoda.jpg' },
        { id: 6,  name: 'Veg Cutlet ( 4 Pc )', price: '89', offer: '40', org: '100', type: 'veg' , img:'https://cdn.cdnparenting.com/articles/2020/03/07132833/328074536.webp' },
        { id: 7,  name: 'Poha', price: '59', offer: '59', org: '100', type: 'veg' , img:'https://media.istockphoto.com/id/670526200/photo/poha-indian-snacks.jpg?s=612x612&w=0&k=20&c=iEVKSfRzmcHxPIz1fKQBNzmcRpLbseh5vjXaRVckRZA=' },
        { id: 8,  name: 'Poori Bhaji (4 Pc)', price: '69', offer: '40', org: '100', type: 'veg' , img:'https://cdn.cdnparenting.com/articles/2020/02/26144721/PURI-BHAJI-RECIPE.webp' },
        { id: 9,  name: 'Pav Bhaji ( 4 Pc )', price: '79', offer: '40', org: '100', type: 'veg' , img:'https://e1.pxfuel.com/desktop-wallpaper/196/259/desktop-wallpaper-17-best-pav-bhaji-places-in-mumbai-in-curlytales.jpg' },
        { id: 10, name: 'Omelette ( 2 Pc )', price: '49', offer: '40', org: '100', type: 'non-veg' , img:'https://w0.peakpx.com/wallpaper/619/437/HD-wallpaper-food-omelette.jpg' },
        { id: 11, name: 'Bread Omelette ( 2 Pc )', price: '69', offer: '40', org: '100', type: 'non-veg' , img:'https://i.pinimg.com/736x/27/27/fb/2727fbef997cedbd2aa3e1e4c576f32d.jpg' },
        { id: 12, name: 'Half Fry Omelette ( 2 Pc )', price: '49', offer: '40', org: '100', type: 'non-veg' , img:'https://i.ytimg.com/vi/knXqJcpqaCU/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLBwiIKjWHjdA3BDvpqc8jnWJhqxhA' },
        { id: 13, name: 'Maggi', price: '29', offer: '40', org: '100', type: 'veg' , img:'https://i.pinimg.com/originals/71/d6/db/71d6db959ed3f8e47e7ef8ccbf74ea7f.jpg' },
        { id: 14, name: 'Vegitable Maggi', price: '39', offer: '40', org: '100', type: 'veg' , img:'https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=1080/assets/search/usecase/vegetable_maggi_zdish.png' },
        { id: 15, name: 'Egg Maggi', price: '49', offer: '40', org: '100', type: 'non-veg' , img:'https://www.licious.in/blog/wp-content/uploads/2020/12/Egg-Maggi.jpg' },
     
    ],
  },
  {
    category: 'Beverages',
    products: [
        {  id: 1, name: 'Cappuccino', price: '60', offer: '15', org: '55', type: 'veg' , img:'https://media.istockphoto.com/id/505168330/photo/cup-of-cafe-latte-with-coffee-beans-and-cinnamon-sticks.jpg?s=612x612&w=0&k=20&c=h7v8kAfWOpRapv6hrDwmmB54DqrGQWxlhP_mTeqTQPA=' },
        {  id: 2, name: 'Drinking Water', price: 'MRP', offer: '', org: '', type: 'veg' , img:'https://e1.pxfuel.com/desktop-wallpaper/47/681/desktop-wallpaper-best-4-mineral-water-on-hip-drinking-water.jpg' },
        {  id: 3, name: 'Cold Drink', price: 'MRP', offer: '', org: '', type: 'veg' , img:'https://e1.pxfuel.com/desktop-wallpaper/47/681/desktop-wallpaper-best-4-mineral-water-on-hip-drinking-water.jpg' },
        {  id: 4, name: 'Butter Milk', price: '60', offer: '10', org: '65', type: 'veg' , img:'https://t3.ftcdn.net/jpg/04/94/26/98/360_F_494269829_Plv0Cdy4U0xTEObr0AdmNngquQmTg75v.jpg' },
        {  id: 5, name: 'Lassi', price: '89', offer: '5', org: '85', type: 'veg' , img:'https://media.istockphoto.com/id/1008799838/photo/image-of-a-glass-of-lassi-made-from-milk-curd.jpg?s=612x612&w=0&k=20&c=L--UXX-L2bsK6Jy-mEigONFj9QEJyvU8b77FJnBLpBg=' },
        {  id: 6, name: 'Tea', price: '40', offer: '20', org: '45', type: 'veg' , img:'https://images6.alphacoders.com/329/329631.jpg' },
    ],
  },
  {
    category: 'Mocktails',
    products:[
      { id: 1, name: 'Fresh Lime Soda', price: '79', offer: '12', org: '70', type: 'veg' , img:'https://soufflebombay.com/wp-content/uploads/2017/06/Homemade-Lime-Soda.jpg' },
      { id: 2, name: 'Masala Soda Lemonade', price: '99', offer: '8', org: '90', type: 'veg' , img:'https://foodtrails25.com/wp-content/uploads/2020/05/MAsala-Soda.jpeg' },
      { id: 3, name: 'Blue Lagoon', price: '110', offer: '15', org: '95', type: 'veg' , img:'https://img-global.cpcdn.com/recipes/6b5756fea006ff56/400x400cq70/photo.jpg' },
      { id: 4, name: 'Virgin Mojito', price: '110', offer: '10', org: '100', type: 'veg' , img:'https://d3cm4d6rq8ed33.cloudfront.net/media/navpravartakfiles/19/e2bc7544-5d79-443b-9bd0-1611d6b90b82.jpg' },
      { id: 5, name: 'Strawberry Mojito', price: '110', offer: '7', org: '105', type: 'veg' , img:'https://t3.ftcdn.net/jpg/01/79/28/38/360_F_179283853_wy0INSO5QHGENOK6J8ovaD8aGysKe9ZR.jpg' },
      { id: 6, name: 'Watermelon Mojito', price: '110', offer: '20', org: '80', type: 'veg' , img:'https://oldtennesseedistillingco.com/wp-content/uploads/2020/08/watermelon-mojito.jpeg' },
    ]
  },
  {
    category: 'Shakes',
    products: [
        { id: 1, name: 'Cold Coffee', price: '110', offer: '10', org: '100', type: 'veg' , img:'https://images7.alphacoders.com/132/1323971.png' },
        { id: 2, name: 'Cold Coffee With Ice Cream', price: '120', offer: '15', org: '110', type: 'veg' , img:'https://www.mrcoconut.in/img/products/22_10_2021_15_50_909_pm.webp' },
        { id: 3, name: 'Vanilla/Strawberry Shake', price: '120', offer: '10', org: '110', type: 'veg' , img:'https://nadialim.com/wp-content/uploads/2020/09/Strawberry-vanilla-smoothie.jpg' },
        { id: 4, name: 'Chocolate Shake', price: '130', offer: '12', org: '115', type: 'veg' , img:'https://img.freepik.com/free-photo/gourmet-dark-chocolate-milkshake-with-marshmallow-generative-ai_188544-12363.jpg' },
        { id: 5, name: 'Oreo Shake', price: '130', offer: '8', org: '120', type: 'veg' , img:'https://t3.ftcdn.net/jpg/05/32/01/40/360_F_532014030_SIKl4Vkabl5SL1MZaF14UP8nELZNmPBk.jpg' },
        { id: 6, name: 'Butterscotch Shake', price: '130', offer: '10', org: '118', type: 'veg' , img:'https://i0.wp.com/thebakernest.com/wp-content/uploads/2021/06/butterscotch-shake.jpg?fit=10002C1000&ssl=1' },
        { id: 7, name: 'Mango Shake', price: '120', offer: '7', org: '112', type: 'veg' , img:'https://www.indianhealthyrecipes.com/wp-content/uploads/2021/04/mango-milkshake-recipe.jpg' },
        { id: 8, name: 'Kesar Pista Shake', price: '139', offer: '12', org: '125', type: 'veg' , img:'https://img-global.cpcdn.com/recipes/4016d5b966a1b23e/680x482cq70/kesar-badam-pista-milkshake-recipe-main-photo.jpg'}
    ],
  },
  {
    category: 'Sandwich',
    products: [
      { id: 1, name: 'Veg Club Sandwich', price: '110', offer: '15', org: '130', type: 'veg' , img:'https://www.indianhealthyrecipes.com/wp-content/uploads/2019/05/club-sandwich-recipe.jpg' },
      { id: 2, name: 'Grilled Sandwich', price: '99', offer: '10', org: '110', type: 'veg' ,img:'https://www.vegrecipesofindia.com/wp-content/uploads/2014/01/grilled-sandwich-4.jpg' },
      { id: 3, name: 'Paneer Tikka Sandwich', price: '120', offer: '12', org: '135', type: 'veg' , img:'https://img-global.cpcdn.com/recipes/03154065feedc1d3/1200x630cq70/photo.jpg' },
      { id: 4, name: 'Cheese Corn Sandwich', price: '110', offer: '8', org: '120', type: 'veg' , img:'https://static.toiimg.com/thumb/57853896.cms?imgsize=1542881&width=800&height=800' },
      { id: 5, name: 'Chicken Tikka Sandwich', price: '130', offer: '15', org: '150', type: 'non-veg' ,img:'https://i.ytimg.com/vi/0Vj0Bl8QNjk/maxresdefault.jpg' },
      { id: 6, name: 'Veg Club Sandwich', price: '130', offer: '10', org: '145', type: 'non-veg' , img:'https://kabilarestro.com/wp-content/uploads/2023/05/Non-Veg-club-Sandwich.jpg' },
      { id: 7, name: 'Grilled Sandwich', price: '120', offer: '12', org: '135', type: 'non-veg' , img:'https://images.unsplash.com/photo-1528736235302-52922df5c122?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8Z3JpbGxlZCUyMHNhbmR3aWNofGVufDB8fDB8fHww' },
      { id: 8, name: 'Paneer Tikka Sandwich', price: '130', offer: '15', org: '150', type: 'non-veg' , img:'https://static.toiimg.com/thumb/60043279.cms?width=1200&height=900' },
      { id: 9, name: 'Cheese Corn Sandwich', price: '140', offer: '18', org: '170', type: 'non-veg' , img:'https://www.vegrecipesofindia.com/wp-content/uploads/2013/07/corn-sandwich-recipe-01a.jpg' },
    ]
  },
  {
    category: 'Soup',
    products:[
      { id: 1,  name: 'Cream Of Tomato Soup', price: '110', offer: '10', org: '95', type: 'veg' , img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwRIK1veP3te5K9P3QlJRWtal6muuxVSyhHBte0YHz3g&s' },
      { id: 2,  name: 'Hot & Sour Soup', price: '110', offer: '12', org: '98', type: 'veg' , img:'https://aartimadan.com/wp-content/uploads/2020/11/hot-and-sour-soup.jpg' },
      { id: 3,  name: 'Sweet Corn Soup', price: '110', offer: '8', org: '100', type: 'veg' , img:'https://www.bigbasket.com/media/uploads/recipe/w-l/1132_1.jpg' },
      { id: 4,  name: 'Peaking Soup', price: '110', offer: '15', org: '90', type: 'veg' , img:'https://media.istockphoto.com/id/673531068/photo/hot-and-sour-soup.jpg?s=612x612&w=0&k=20&c=qd_Fro8GIZcLF8nO9XoAkcKAKehQBu061c7ZH_ikks0=' },
      { id: 5,  name: 'Manchow Soup', price: '115', offer: '10', org: '105', type: 'veg' , img:'https://www.chefkunalkapur.com/wp-content/uploads/2021/03/Veg-Manchow-Soup-scaled.jpeg?v=1621618246' },
      { id: 6,  name: 'Clear Soup', price: '110', offer: '12', org: '98', type: 'veg' , img:'https://i.ytimg.com/vi/KjxCEm-pD2k/maxresdefault.jpg' },
      { id: 7,  name: 'Cream Of Mushroom Soup', price: '115', offer: '8', org: '110', type: 'veg' , img:'https://static.toiimg.com/photo/75454410.cms' },
      { id: 8,  name: 'Lemon Coriander Soup', price: '110', offer: '15', org: '93', type: 'veg' , img:'https://i0.wp.com/dishesandflavors.com/wp-content/uploads/2021/08/Lemon-Coriander-Soup.jpg?fit=51842C3456&ssl=1' },
      { id: 9,  name: 'Hot & Sour Soup', price: '120', offer: '10', org: '110', type: 'non-veg' , img:'https://aartimadan.com/wp-content/uploads/2020/11/hot-and-sour-soup.jpg' },
      { id: 10, name: 'Sweet Corn Soup', price: '120', offer: '12', org: '105', type: 'non-veg' , img:'https://indianheartbeat.in/wp-content/uploads/2023/01/sweet-corn-soup.jpg' },
      { id: 11, name: 'Peaking Soup', price: '120', offer: '8', org: '118', type: 'non-veg' , img:'https://cdn.sanity.io/images/2r0kdewr/production/fce2ea8994e36974df87ca6aeda7228833e6fc26-6000x3502.jpg?w=600&q=50' },
      { id: 12, name: 'Manchow Soup', price: '125', offer: '15', org: '105', type: 'non-veg' , img:'https://c.ndtvimg.com/2020-07/k3990ffo_soup_625x300_24_July_20.jpg' },
      { id: 13, name: 'Clear Soup', price: '125', offer: '10', org: '120', type: 'non-veg' , img:'https://yummyies.com/wp-content/uploads/2021/04/chicken-clear.jpg' },
      { id: 14, name: 'Cream Of Mushrom Soup', price: '125', offer: '12', org: '112', type: 'non-veg' , img:'https://theveglife.com/wp-content/uploads/2014/10/Cream-of-Mushroom-Soup-Close-Up.jpg' },
    ]
  },
  {
    category: 'Burgers',
    products:[
      { id: 1, name: 'Veg Burger With Cheese', price: '110', offer: '10', org: '100', type: 'veg' , img:'https://static.toiimg.com/thumb/55201507.cms?width=1200&height=900' },
      { id: 2, name: 'Paneer Tikka Burger', price: '120', offer: '15', org: '110', type: 'veg' , img:'https://coox-new.s3.ap-south-1.amazonaws.com/images/d/dishes/Paneer%20Tikka%20Burger-undefined-dish-img.jpeg?v=1627296847405' },
      { id: 3, name: 'Chicken Burger', price: '130', offer: '12', org: '118', type: 'non-veg' , img:'https://coox-new.s3.ap-south-1.amazonaws.com/images/d/dishes/Paneer%20Tikka%20Burger-undefined-dish-img.jpeg?v=1627296847405' },
      { id: 4, name: 'Chilli Chicken Burger', price: '130', offer: '8', org: '120', type: 'non-veg' , img:'https://realfood.tesco.com/media/images/RFO-1400x919-SpicyChickenBurger-98fc9913-fd2d-4559-b047-086ff4871a01-0-1400x919.jpg' },
      { id: 5, name: 'Chicken Tikka Burger', price: '130', offer: '10', org: '118', type: 'non-veg' , img:'https://d3gy1em549lxx2.cloudfront.net/854cb359-11d2-4a8d-a4d4-bafc06b6dd7c.jpg' },
      { id: 6, name: 'Jumbo Burger', price: '210', offer: '20', org: '168', type: 'non-veg' , img:'https://upload.wikimedia.org/wikipedia/commons/7/78/Jumbo_Burger.jpg' },
    ]
  },
  {
    category: 'Indian Pasta',
    products: [
      { id: 1, name: 'Arrabbita (Red Sauce Pasta - Veg)', price: '169', offer: '15', org: '200', type: 'veg' ,img:'https://vaya.in/recipes/wp-content/uploads/2018/02/Vegetarian-Penne-Arrabiata-Pasta.jpg' },
      { id: 2, name: 'Alfredo (White Sauce Pasta - Veg)', price: '169', offer: '10', org: '190', type: 'veg' , img:'https://i.ytimg.com/vi/Y9ffxQ3ZieM/maxresdefault.jpg' },
      { id: 3, name: 'Mix Sauce Pasta - Veg', price: '179', offer: '20', org: '225', type: 'veg' , img:'https://static.toiimg.com/photo/83107712.cms' },
      { id: 4, name: 'Arrabbita (Red Sauce Pasta - Non-Veg)', price: '189', offer: '18', org: '230', type: 'non-veg' , img:'https://www.recipetineats.com/wp-content/uploads/2023/10/Penne-Arrabbiata-4.jpg' },
      { id: 5, name: 'Alfredo (White Sauce Pasta - Non-Veg)', price: '189', offer: '12', org: '215', type: 'non-veg' , img:'https://www.archanaskitchen.com/images/archanaskitchen/0-Archanas-Kitchen-Recipes/2018/Penne_Pasta_Recipe_In_Alfredo_Sauce_With_Roasted_Mushrooms-1.jpg' },
      { id: 6, name: 'Mix Sauce Pasta - Non-Veg', price: '199', offer: '15', org: '235', type: 'non-veg' , img:'https://i.ytimg.com/vi/rE-nF2kNI-k/maxresdefault.jpg' },
    ]
  },
  {
    category: 'Noodles & Rice',
    products: [
      { id: 1, name: 'Hakka Noodles (Veg)', price: '180', offer: '15', org: '210', type: 'veg' , img:'https://vegecravings.com/wp-content/uploads/2017/03/veg-hakka-noodles-recipe-with-step-by-step-instructions.jpg' },
      { id: 2, name: 'Singapuri Noodles (Veg)', price: '180', offer: '10', org: '200', type: 'veg' , img:'https://cookingfromheart.com/wp-content/uploads/2019/11/Veg-Singapore-Noodles-1.jpg' },
      { id: 3, name: 'Chowmein (Veg)', price: '180', offer: '20', org: '225', type: 'veg' , img:'https://i0.wp.com/dishesandflavors.com/wp-content/uploads/2021/01/Veg-Chow-Mein.jpg?fit=5184%2C3456&ssl=1' },
      { id: 4, name: 'Chilli Garlic Noodles (Veg)', price: '180', offer: '18', org: '230', type: 'veg' , img:'https://cdn.tarladalal.com/members/9306/procstepimgs/chilli-garlic-noodles-(11)-11-188874.jpg' },
      { id: 5, name: 'Fried Rice (Veg)', price: '180', offer: '12', org: '205', type: 'veg' , img:'https://www.indianveggiedelight.com/wp-content/uploads/2020/06/veg-fried-rice-featured.jpg' },
      { id: 6, name: 'Egg Chowmein', price: '180', offer: '15', org: '250', type: 'non-veg' , img:'https://khadyaa.com/wp-content/uploads/2016/07/egg-chowmein-012-copy-600x600.jpg' },
      { id: 7, name: 'Egg Fried Rice', price: '180', offer: '10', org: '230', type: 'non-veg' , img:'https://i0.wp.com/seonkyounglongest.com/wp-content/uploads/2020/02/Egg-Fried-Rice-2.jpg?fit=1300%2C867&ssl=1' },
      { id: 8, name: 'American Chopsuey (Veg)', price: '230', offer: '15', org: '270', type: 'veg' , img:'https://www.vegrecipesofindia.com/wp-content/uploads/2017/11/veg-american-chopsuey-recipe-1.jpg' },
      { id: 9, name: 'Shezwan Rice (Veg)', price: '180', offer: '12', org: '205', type: 'veg' , img:'https://content.jwplatform.com/thumbs/y7wvIS9T-720.jpg' },
      { id: 10, name: 'Schezwan Noodles (Veg)', price: '180', offer: '15', org: '210', type: 'veg' , img:'https://images.squarespace-cdn.com/content/v1/5ec30182cff13b4331c5384d/c14fb1c8-7e2c-4ad3-9561-f3061ccc1e55/11111626-30C4-4406-BA90-168781AACD56.png?format=1000w' },
      { id: 11, name: 'Paneer Fried Rice (Veg)', price: '190', offer: '18', org: '230', type: 'veg' , img:'https://www.indianhealthyrecipes.com/wp-content/uploads/2021/07/paneer-fried-rice-recipe.jpg' },
      { id: 12, name: 'Paneer Chowmein (Veg)', price: '190', offer: '10', org: '210', type: 'veg' , img:'https://i.pinimg.com/736x/7e/b9/d4/7eb9d40c7aa9dfaa386a8c4a8655124e.jpg' },
      { id: 14, name: 'Hakka Noodles (Non-Veg)', price: '210', offer: '20', org: '250', type: 'non-veg' , img:'https://www.whiskaffair.com/wp-content/uploads/2018/03/Chicken-Hakka-Noodles-2-3.jpg' },
      { id: 15, name: 'Singapuri Noodles (Non-Veg)', price: '210', offer: '18', org: '245', type: 'non-veg' , img:'https://www.slimmingeats.com/blog/wp-content/uploads/2012/11/singapore-noodles-25-2.jpg' },
      { id: 16, name: 'Chowmein (Non-Veg)', price: '210', offer: '15', org: '235', type: 'non-veg' , img:'https://www.dokan.co.in/wp-content/uploads/2019/11/mix-chow-mein-01.jpg' },
      { id: 17, name: 'Chilli Garlic Noodles (Non-Veg)', price: '210', offer: '10', org: '245', type: 'non-veg' , img:'https://hot-thai-kitchen.com/wp-content/uploads/2021/11/chili-garlic-noodles-blog.jpg' },
      { id: 18, name: 'Fried Rice (Non-Veg)', price: '210', offer: '12', org: '250', type: 'non-veg' , img:'https://www.positivenewstrends.com/wp-content/uploads/2019/05/Chicken-Fried-Rice-Ready-1.png' },
      { id: 21, name: 'American Chopsuey (Non-Veg)', price: '250', offer: '12', org: '240', type: 'non-veg'  , img:'https://www.vegrecipesofindia.com/wp-content/uploads/2017/11/american-chopsuey-recipe-1a.jpg'},
      { id: 22, name: 'Shezwan Rice (Non-Veg)', price: '210', offer: '15', org: '250', type: 'non-veg' , img:'https://www.nangaihomemadefood.com/product-images/download.jpeg/1746481000000637025/600x600'},
      { id: 23, name: 'Schezwan Noodles (Non-Veg)', price: '250', offer: '18', org: '305', type: 'non-veg' , img:'https://www.cookwithmanali.com/wp-content/uploads/2021/08/Schezwan-Noodles-500x500.jpg' },
    ]
  },
  {
    category: 'Indian Tandoori',
    products: [
      { id: 1, name: 'Paneer Tikka', price: '280', offer: '20', org: '350', type: 'veg' , img:'https://images.news18.com/ibnkhabar/uploads/2023/03/Amritsari-Paneer-Tikka.jpg' },
      { id: 2, name: 'Paneer Malai Tikka', price: '299', offer: '15', org: '350', type: 'veg' , img:'https://i.ytimg.com/vi/yIDnDMe9Dbk/maxresdefault.jpg' },
      { id: 3, name: 'Paneer Hariyali Tikka', price: '289', offer: '10', org: '320', type: 'veg' , img:'https://s3.amazonaws.com/appforest_uf/f1553077582027x256878919194545760/Hariyali%20Paneer%20Tikka.jpg' },
      { id: 4, name: 'Paneer Kalimirch Tikka', price: '289', offer: '8', org: '315', type: 'veg' , img:'https://saffronchronicles.files.wordpress.com/2013/08/share2.jpeg' },
      { id: 5, name: 'Hara Bhara Kebab', price: '280', offer: '12', org: '320', type: 'veg' , img:'https://cookingfromheart.com/wp-content/uploads/2017/06/Hara-Bhara-Kabab-2.jpg' },
      { id: 6, name: 'Dahi Ke Kebab', price: '169', offer: '5', org: '180', type: 'veg' , img:'https://img-global.cpcdn.com/recipes/473c6a44c27af4cd/680x482cq70/dahi-kebab-recipe-main-photo.jpg' },
      { id: 7, name: 'Veg Shami Kebab', price: '179', offer: '15', org: '210', type: 'veg' , img:'https://www.chefkunalkapur.com/wp-content/uploads/2023/07/veg-kebab-recipe-1300x731.jpg?v=1689827628' },
      { id: 8, name: 'Tandoori Mushroom', price: '289', offer: '10', org: '320', type: 'veg' , img:'https://cookingfromheart.com/wp-content/uploads/2017/02/Tandoori-Mushroom-Tikka-5.jpg' },
      { id: 9, name: 'Tandoori Soya Chaap', price: '189', offer: '7', org: '205', type: 'veg' , img:'https://staticimg.amarujala.com/assets/images/2021/09/15/750x506/tandoori-soya-chaap_1631702105.jpeg' },
      { id: 10, name: 'Chatpati Soya Chaap', price: '189', offer: '8', org: '205', type: 'veg' , img:'https://img-global.cpcdn.com/recipes/525120bdae65dd9f/1200x630cq70/photo.jpg' },
      { id: 11, name: 'Malai Soya Chaap', price: '199', offer: '12', org: '225', type: 'veg' , img:'https://images.saymedia-content.com/.image/t_share/MTc0NDI4MzE1MDk0ODg2MDIy/soya-chaap-recipe-malai-chaap-recipe-soyabean-in-a-creamy-sauce.jpg' },
      { id: 12, name: 'Achari Soya Chaap', price: '189', offer: '5', org: '200', type: 'veg' , img:'https://lh3.googleusercontent.com/tPIGpP-kiRxm-xyD-SvrmwKRCTtOluLdotey35_lOpcH1HTOzNaw7sLsQsgZOyB5F3-b8CUVmAHgtRVhtNzFWeAMyfYNBxcVXMWCPaRf=w512-rw' },
      { id: 13, name: 'Afgani Soya Chaap', price: '199', offer: '10', org: '220', type: 'veg', img:'https://nishamadhulika.com/imgpst/featured/soya_chaap_white_gravy.jpg' },
      { id: 14, name: 'Tandoori Chicken (Half/Full)', price: '219/399', offer: '15', org: '260/470', type: 'non-veg' , img:'https://www.hotelwelkinresidency.com/wp-content/uploads/2017/12/Tandoori-Chicken.jpg' },
      { id: 15, name: 'Chicken Fry With Bone (Half/Full)', price: '219/399', offer: '12', org: '250/450', type: 'non-veg' , img: 'https://flavoursofmykitchen.com/wp-content/uploads/2022/09/IMG_0943-scaled.jpeg' },
      { id: 15, name: 'Chicken Fry With Bone (Half/Full)', price: '219/399', offer: '12', org: '250/450', type: 'non-veg' , img: 'https://www.foodandwine.com/thmb/wlqi21PyylKMLXJCMpEuomlhr4E=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/indiana-style-fried-chicken-ft-recipe0620-6e63cda36a464c24bd9ada92f780a4c6.jpg' },
      { id: 16, name: 'Chicken Tikka', price: '279', offer: '18', org: '550', type: 'non-veg' , img: 'https://img.freepik.com/premium-photo/indian-chicken-tikka-kebabs-marinated-spices-yogurt-roasted-tandoor-served-with-green-chutney-onion-selective-focus_466689-77108.jpg' },
      { id: 17, name: 'Chicken Malai Tikka', price: '289', offer: '20', org: '500', type: 'non-veg' , img: 'https://www.shutterstock.com/image-photo/indian-afghani-chicken-malai-tikka-600nw-1922852990.jpg' },
      { id: 18, name: 'Murg Pahadi Tikka', price: '279', offer: '10', org: '310', type: 'non-veg' , img: 'https://media-cdn.tripadvisor.com/media/photo-s/10/db/d6/e5/chicken-pahadi-tikka.jpg' },
      { id: 19, name: 'Chicken Angara', price: '289', offer: '8', org: '315', type: 'non-veg' , img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKbeOxLbkKmzyQy51JPewi0AD815y10JMyp6U-ULn4-g&s' },
      { id: 20, name: 'Murg Afgani Tikka', price: '279', offer: '12', org: '320', type: 'non-veg' , img: 'https://www.shutterstock.com/image-photo/indian-afghani-chicken-malai-tikka-600nw-1912352347.jpg' },
      { id: 21, name: 'Murg Kalimirch Tikka', price: '279', offer: '15', org: '330', type: 'non-veg' , img: 'https://i.ytimg.com/vi/iw-E__FFnT8/maxresdefault.jpg' },
      { id: 22, name: 'Murg Lehsuni Tikka', price: '279', offer: '10', org: '310', type: 'non-veg' , img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgemgjB7sRg9pOcNvzoF1eFp8FeywkWVptNVYnt-_VFQ&s' },
      { id: 23, name: 'Murg Patiyala Tikka', price: '279', offer: '8', org: '305', type: 'non-veg' , img: 'https://myheartbeets.com/wp-content/uploads/2020/01/instant-pot-punjabi-chicken-patiala-500x375.jpg' },
      { id: 24, name: 'Murg Achari Tikka', price: '279', offer: '5', org: '300', type: 'non-veg' , img: 'https://lh3.googleusercontent.com/ipIvwOAXYnL3fAr4uCpbHIGVNBUE0L75XtkYKoe3JT4Zan28pPc5lmdtZeoXdiMx1dVHpQFfTSsrPucfGYDDv4FErCqYMZ2ZijL8HUhA=w512-rw' },
      { id: 25, name: 'Chicken Seek Kebab', price: '279', offer: '10', org: '320', type: 'non-veg' , img: 'https://t4.ftcdn.net/jpg/02/28/09/67/360_F_228096754_nK2RWPidbk69ftKQIjjfjEA6NgZI20mg.jpg' },
      { id: 26, name: 'Mutton Seek Kebab', price: '289', offer: '5', org: '300', type: 'non-veg' , img: 'https://www.shutterstock.com/image-photo/seekh-kabab-made-minced-chicken-260nw-2108952152.jpg' },
      { id: 27, name: 'Mutton Shami Kebab (4 Pc)', price: '170', offer: '8', org: '185', type: 'non-veg' , img: 'https://m.media-amazon.com/images/I/71LtwNBLoxL._AC_UF1000,1000_QL80_.jpg' },
      { id: 28, name: 'Galawati Kebab (4 Pc)', price: '170', offer: '15', org: '650', type: 'non-veg' , img: 'https://www.shutterstock.com/image-photo/tunde-ke-kabab-known-buffalo-260nw-2117250350.jpg' },
      { id: 29, name: 'Special Veg Plater', price: '449', offer: '10', org: '480', type: 'veg' , img: 'https://t4.ftcdn.net/jpg/02/75/39/23/360_F_275392381_9upAWW5Rdsa4UE0CV6gRu2CwUETjzbKy.jpg' },
      { id: 30, name: 'Khazana Plater Non Veg', price: '550', offer: '12', org: '450', type: 'non-veg' , img: 'https://www.shutterstock.com/image-photo/madurai-india-nov-4-2020-260nw-1852796557.jpg' },
      // Add more items as needed
    ]
  },
  {
    category: 'Pizza',
    products: [
      { id: 1, name: 'Garlic Bread With Cheese (4pc)', price: '120', offer: '10', org: '135', type: 'veg' , img: 'https://t4.ftcdn.net/jpg/03/19/22/35/360_F_319223572_ILWIWBuhaeyTzGPLQ0rJCVtBSGOqw864.jpg' },
      { id: 2, name: 'Tomato Cheese Capsicum Onion Pizza', price: '169', offer: '15', org: '200', type: 'veg' , img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdawG0NznV_EjtxatQ-loTf7vr3WDU51AqhcNx-fy_cQ&s' },
      { id: 3, name: 'Paneer Chilly Pizza', price: '179', offer: '12', org: '205', type: 'veg' , img: 'https://b.zmtcdn.com/data/dish_photos/a2f/b5d28dd82d6c1589404f4a72dd214a2f.jpg' },
      { id: 4, name: 'Paneer Tikka Pizza', price: '199', offer: '18', org: '245', type: 'veg' , img: 'https://lh3.googleusercontent.com/5PtWUejd75p67nFY54VcRb5mxbAk8xRANZITvhvW-LcqaLJp_oy4B4iAkgF0o7D9PtmvhV6UrCa9rF2-9yiiFmv9Bwb61igYDmiW23hZ=w512-rw' },
      { id: 5, name: 'Cheese Corn Pizza', price: '199', offer: '20', org: '250', type: 'veg' , img: 'https://i0.wp.com/imovers.in/wp-content/uploads/2022/02/CORN-PIZZA.jpg' },
      { id: 6, name: 'Chicken Chilli Pizza', price: '210', offer: '14', org: '245', type: 'non-veg' , img: 'https://media.istockphoto.com/id/186295807/photo/chicken-tikka-pizza.jpg?s=612x612&w=0&k=20&c=gnF-DKlj1JCCAhzQ4fB8WEyJ_cUlgl1i-ZNsIuvZ54U=' },
      { id: 7, name: 'Chicken Tikka Pizza', price: '230', offer: '17', org: '275', type: 'non-veg' , img: 'https://media.istockphoto.com/id/1043604390/photo/butter-chicken-pizza.jpg?s=612x612&w=0&k=20&c=ahj3y6ktAvzcINubn0a0BfvovLTGDx_J_aMju4szqVQ=' },
      { id: 8, name: 'Margherita Pizza', price: '230', offer: '13', org: '265', type: 'veg' , img: 'https://media.istockphoto.com/id/1393150881/photo/italian-pizza-margherita-with-cheese-and-tomato-sauce-on-the-board-on-grey-table-macro-close.jpg?s=612x612&w=0&k=20&c=kL0Vhg2XKBjEl__iG8sFv31WTiahdpLc3rTDtNZuD2g=' },
      { id: 9, name: 'Pizza-E-Bahar', price: '250', offer: '16', org: '295', type: 'veg' , img: 'https://w0.peakpx.com/wallpaper/228/117/HD-wallpaper-pizza-slice-fast-food-cheese-green-peppers-food.jpg' },
      // Add more pizzas as needed
    ]
  },
  {
    category: 'Momos',
    products: [
      { id: 1, name: 'Momos Delicious', price: '109', offer: '12', org: '125', type: 'veg' , img: 'https://media.istockphoto.com/id/1292635321/photo/veg-steam-momo-nepalese-traditional-dish-momo-stuffed-with-vegetables-and-then-cooked-and.jpg?s=612x612&w=0&k=20&c=NyxQvDnBq7Ki09Zi21JEMxpuZ_uVr45ZBSavqXJ2T1s=' },
      { id: 2, name: 'Steam Veg Momos', price: '119', offer: '15', org: '140', type: 'veg' , img: 'https://storeassets.im-cdn.com/temp/cuploads/ap-south-1%3Aabd0d01f-2fd7-4231-8a23-b7b68eaf18de/bakasurmomos/products/1637829873261Veg-Steam-Momos.jpeg' },
      { id: 3, name: 'Fry Veg Momos', price: '119', offer: '10', org: '130', type: 'veg' , img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRgP-MzHRgIsEGX8kyDuNmm3A4oEwSnmv2ABGXoW9eW0g&s' },
      { id: 4, name: 'Tandoori Veg Momos', price: '119', offer: '8', org: '130', type: 'veg' , img: 'https://www.chefkunalkapur.com/wp-content/uploads/2021/09/CW0_9295-1300x865.jpg?v=1632032703' },
      { id: 5, name: 'Kurkure Veg Momos', price: '139', offer: '18', org: '170', type: 'veg' , img: 'https://www.zippyfeed.com/wp-content/uploads/2023/02/Veg-Kurkure-momo-1980x1320-1.jpg' },
      { id: 6, name: 'Steam Paneer Momos', price: '149', offer: '14', org: '175', type: 'veg' , img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8qCRY1cWMlKjjQLqiX5tol7uXFCwpufiwACJmYKhU5A&s' },
      { id: 7, name: 'Fry Paneer Momos', price: '139', offer: '20', org: '175', type: 'veg' , img: 'https://www.zippyfeed.com/wp-content/uploads/2023/01/Tandoori-Paneer-Fried-momo.jpg' },
      { id: 8, name: 'Tandoori Paneer Momos', price: '149', offer: '12', org: '170', type: 'veg' , img: 'https://img-global.cpcdn.com/recipes/631f7db78bd8a38a/680x482cq70/tandoori-paneer-momos-recipe-main-photo.jpg' },
      { id: 9, name: 'Paneer Kurkure Momos', price: '149', offer: '15', org: '175', type: 'veg' , img: 'https://lh3.googleusercontent.com/4MUWtNm1spya27CJ23iLpnB-DbsQsb3RwZrZreI5eBvP31Q6SJJR8SoBHVgcSc8F9TebYYn-3o1W8syHt4tuZomElplE_FLaHOslqXCT8A=w512-rw' },
      { id: 10, name: 'Steam Chicken Momos', price: '149', offer: '10', org: '165', type: 'non-veg' , img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXqxphUKbVR5TTqm-Pku149Cvl8qu3IrCamFli9PfukA&s' },
      { id: 11, name: 'Fry Chicken Momos', price: '149', offer: '8', org: '160', type: 'non-veg' , img: 'https://salasdaily.com/cdn/shop/products/chicken_fried_momos.webp?v=1667534046' },
      { id: 12, name: 'Tandoori Chicken Momos', price: '169', offer: '18', org: '205', type: 'non-veg' , img: 'https://i0.wp.com/prasuma.com/wp-content/uploads/2020/11/Tandoori-Momo.jpg?fit=800%2C470&ssl=1' },
      { id: 13, name: 'Kurkure Chicken Momos', price: '169', offer: '15', org: '200', type: 'non-veg' , img: 'https://lh3.googleusercontent.com/cZpU6YoYqq0fuB6kBf6EzVG1xRUV8zKPzcI7IoPdDf_2cO_DM4DFgz16PAKgWAWrUVz5H8gz8dNxJ5g6r2XbCM0RCZzsIeD2VCvjcaa7=w512-rw' },
      // Add more momos variations as needed
    ]
  },
  {
    category: 'Chinese Main Course',
    products: [
      { id: 1, name: 'Chilli Paneer Gravy', price: '280', offer: '15', org: '325', type: 'veg' , img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjJ-3zdyWAK2PgD4Qw3iAuO70qSTc5NYO3Ss5RTmZxxg&s' },
      { id: 2, name: 'Paneer Manchurian Gravy', price: '280', offer: '12', org: '320', type: 'veg' , img: 'https://www.yummytummyaarthi.com/wp-content/uploads/2015/05/1-30.jpg' },
      { id: 3, name: 'Mushroom Chilli Gravy', price: '280', offer: '10', org: '310', type: 'veg' , img: 'https://www.yummytummyaarthi.com/wp-content/uploads/2017/09/1..jpg' },
      { id: 4, name: 'Veg Manchurian Gravy', price: '260', offer: '18', org: '315', type: 'veg' , img: 'https://www.vegrecipesofindia.com/wp-content/uploads/2012/05/veg-manchurian-gravy-recipe-1b.jpg' },
      { id: 5, name: 'Chicken Chilli Gravy', price: '280', offer: '20', org: '350', type: 'non-veg' , img: 'https://media-cdn.tripadvisor.com/media/photo-s/11/bf/58/8b/chilli-chicken-gravy.jpg' },
      { id: 6, name: 'Chicken Manchurian Gravy', price: '280', offer: '15', org: '325', type: 'non-veg' , img: 'https://curefoods-images.eatfit.in/tr:w-600,ar-0.8,c_fit//image/singles/eat/meals/CHT51_v1/primary/2_1.jpg' },
      // Add more Chinese main course dishes as needed
    ]
  },
  {
    category: 'Sabjiyan',
    products: [
      { id: 1, name: 'Mushroom Kaleji (Our Special)', price: '290', offer: '18', org: '355', type: 'veg' , img: 'https://im.hunt.in/local/Gallery/3030773/l/3030773_a5345.jpg' },
      { id: 2, name: 'Mushroom Matar Masala', price: '280', offer: '15', org: '325', type: 'veg' , img: 'https://i2.wp.com/www.bharatzkitchen.com/wp-content/uploads/2020/12/Mushroom-Matar.jpg?fit=1200%2C675&ssl=1' },
      { id: 3, name: 'Mushroom Masala', price: '280', offer: '12', org: '320', type: 'veg' , img: 'https://greenbowl2soul.com/wp-content/uploads/2021/02/Mushroom-masala-500x375.jpg' },
      { id: 4, name: 'Mushroom Paneer Masala', price: '280', offer: '10', org: '310', type: 'veg' , img: 'https://media.istockphoto.com/id/1195402166/photo/mushroom-paneer-curry-or-sabzi-served-in-a-bowl-selective-focus.jpg?s=612x612&w=0&k=20&c=OLtYU2KsQPBTxvURRVBaAEal53zUwQqjxrFAoNuCqsc=' },
      { id: 5, name: 'Mushroom Do Pyaza', price: '280', offer: '20', org: '350', type: 'veg' , img: 'https://img-global.cpcdn.com/recipes/58a84e104300638b/1200x630cq70/photo.jpg' },
      { id: 6, name: 'Diwani Handi', price: '250', offer: '18', org: '305', type: 'veg' , img: 'https://aartimadan.com/wp-content/uploads/2022/10/Veg-Diwani-Handi.jpg' },
      { id: 7, name: 'Mix Veg', price: '190', offer: '15', org: '225', type: 'veg' , img: 'https://cookingfromheart.com/wp-content/uploads/2019/04/Restaurant-Style-Mixed-Veg-Gravy-1.jpg' },
      { id: 8, name: 'Aloo Jeera', price: '150', offer: '12', org: '170', type: 'veg' , img: 'https://www.mapsofindia.com/ci-moi-images/my-india/Aloo-Jeera.jpg' },
      { id: 9, name: 'Aloo Gobhi Matar', price: '160', offer: '10', org: '180', type: 'veg' , img: 'https://www.whiskaffair.com/wp-content/uploads/2019/06/Aloo-Matar-Gobi-1-3.jpg' },
      { id: 10, name: 'Baby Corn Masala', price: '250', offer: '20', org: '310', type: 'veg' , img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRc-kic7joVTMBtTsT2smyBpVePD-rYptPHgx0YBBJWtg&s' },
      { id: 11, name: 'Veg Jhalfrezi', price: '199', offer: '15', org: '235', type: 'veg' , img: 'https://www.recipemasters.in/wp-content/uploads/2017/01/veg-jalfrezi.jpg' },
      // Add more vegetarian dishes as needed
    ]
  },
  {
    category: 'Chinese Starter',
    products: [
      { id: 1, name: 'Chilli Paneer Dry', price: '280', offer: '15', org: '325', type: 'veg' , img: 'https://www.yummytummyaarthi.com/wp-content/uploads/2023/06/chilli-paneer-1.jpeg' },
      { id: 2, name: 'Paneer 65', price: '280', offer: '12', org: '320', type: 'veg' , img: 'https://www.yummytummyaarthi.com/wp-content/uploads/2013/10/1_phixr-3.jpg' },
      { id: 3, name: 'Paneer Manchurian', price: '280', offer: '10', org: '310', type: 'veg' , img: 'https://vaya.in/recipes/wp-content/uploads/2018/03/Paneer-Manchurian.jpg' },
      { id: 4, name: 'Mushroom Chilli Dry', price: '280', offer: '20', org: '350', type: 'veg' , img: 'https://static.toiimg.com/photo/84753069.cms' },
      { id: 5, name: 'Veg Manchurian Dry', price: '260', offer: '18', org: '315', type: 'veg' , img: 'https://www.yummytummyaarthi.com/wp-content/uploads/2015/11/1-9.jpg' },
      { id: 6, name: 'Crispy Chilli Potato', price: '170', offer: '15', org: '200', type: 'veg' , img: 'https://i.pinimg.com/originals/f2/20/51/f22051754187ab373aa86e240935893f.jpg' },
      { id: 7, name: 'Veg Spring Roll', price: '160', offer: '12', org: '180', type: 'veg' , img: 'https://c1.wallpaperflare.com/preview/564/844/49/nem-chinese-vegetables-chinese-cabbage.jpg' },
      { id: 8, name: 'Veg Salt & Pepper', price: '180', offer: '10', org: '200', type: 'veg' , img: 'https://d3gy1em549lxx2.cloudfront.net/bd5339b2-c969-4c2a-a6fb-2dbc06c4bc53.jpg' },
      { id: 9, name: 'Crispy Chilly Baby Corn', price: '250', offer: '20', org: '310', type: 'veg' , img: 'https://media-cdn.tripadvisor.com/media/photo-s/13/b8/18/39/crispy-chili-baby-corn.jpg' },
      { id: 10, name: 'Crispy Chilly Mushroom', price: '280', offer: '15', org: '325', type: 'veg' , img: 'https://content.jwplatform.com/thumbs/USelkw49-720.jpg' },
      { id: 11, name: 'French Fries', price: '160', offer: '12', org: '180', type: 'veg' , img: 'https://img.freepik.com/free-photo/crispy-french-fries-with-ketchup-mayonnaise_1150-26588.jpg' },
      { id: 12, name: 'Fried Crispy Corn', price: '250', offer: '18', org: '305', type: 'veg' , img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXz1umnbram6NE0wXQglBnaCDaa8Y_FKbfyg&s' },
      { id: 13, name: 'Honey Chilli Potato', price: '199', offer: '15', org: '235', type: 'veg' , img: 'https://myfoodstory.com/wp-content/uploads/2018/10/Honey-Chilli-Potatoes-1.jpg' },
      { id: 14, name: 'Chicken Spring Roll', price: '169', offer: '12', org: '190', type: 'non-veg' , img: 'https://www.manusmenu.com/wp-content/uploads/2015/01/1-Chicken-Spring-Rolls-9-1-of-1.jpg' },
      { id: 15, name: 'Chicken 65', price: '180', offer: '10', org: '200', type: 'non-veg' , img: 'https://www.indianhealthyrecipes.com/wp-content/uploads/2022/03/chicken-65-restaurant-style-500x375.jpg' },
      { id: 16, name: 'Chilli Chicken Dry', price: '180', offer: '15', org: '210', type: 'non-veg' , img: 'https://myfoodstory.com/wp-content/uploads/2019/10/easy-chinese-chilli-chicken-dry-4.jpg' },
      { id: 17, name: 'Crispy Fried Chicken', price: '180', offer: '20', org: '225', type: 'non-veg' , img: 'https://img.freepik.com/free-photo/fried-chicken-french-fries-black-cement-floor_1150-28542.jpg' },
      { id: 18, name: 'Chicken Manchurian Dry', price: '180', offer: '18', org: '220', type: 'non-veg' , img: 'https://media.istockphoto.com/id/1072951288/photo/indian-chilli-chicken-dry-served-in-a-plate-over-moody-background-selective-focus.jpg?s=612x612&w=0&k=20&c=WkAg8lRGsRa4XLt90XdPKIdo5qGe_SfnGzqrNi8sp9g=' },
      { id: 19, name: 'Crunchy Chilli Dry', price: '290', offer: '12', org: '330', type: 'non-veg' , img: 'https://e0.pxfuel.com/wallpapers/887/859/desktop-wallpaper-crispy-chilli-chicken-recipe.jpg' },
      { id: 20, name: 'Lemon Chicken', price: '280', offer: '15', org: '325', type: 'non-veg' , img: 'https://cleanfoodcrush.com/wp-content/uploads/2020/04/cleanfoodcrush-Honey-lemon-chicken-1080x900.jpg' },
      { id: 21, name: 'Drums Of Heaven (6 Pc)', price: '299', offer: '10', org: '330', type: 'non-veg' , img: 'https://lh3.googleusercontent.com/e88Oy17s5XGb-diMA6MeanWVh07gsG9xifpPRh0t7Zj1G0czqdsKqSX2ipXQQF14nVoi6NwSW8cUAyxJeOAT6Bcdy0c9aAL9itqPEjNp=w512-rw' },
      { id: 22, name: 'Chicken Lollipop (6 Pc)', price: '299', offer: '15', org: '350', type: 'non-veg' , img: 'https://media.istockphoto.com/id/1090739066/photo/appetizer-chicken-lollipop.jpg?s=612x612&w=0&k=20&c=Uefuv0OMXVxp0hxWX-hNAZ3vSyaM_wdcUYrMZ7mAd5o=' },
      // Add more Chinese starters as needed
    ]
  },
  {
    category: 'Paneer Dishes',
    products: [
      { id: 1, name: 'Handi Paneer', price: '280', offer: '15', org: '325', type: 'veg' , img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRd9aKRgL6ELrGMvCr0ZRecRvIUMzSLV45RLKQ0i3bbDw&s' },
      { id: 2, name: 'Kadai Paneer', price: '280', offer: '12', org: '320', type: 'veg' , img: 'https://media.istockphoto.com/id/968505648/photo/indian-food-or-indian-curry-in-a-copper-brass-serving-bowl-with-bread-or-roti.jpg?s=612x612&w=0&k=20&c=S8uljX2XbtkXPLYyxbxX-y19pTt-iMr_X0JMSviQnT8=' },
      { id: 3, name: 'Paneer Lababdar', price: '290', offer: '10', org: '320', type: 'veg' , img: 'https://images.news18.com/ibnkhabar/uploads/2023/05/Paneer-Lababdar-16850078483x2.jpg' },
      { id: 4, name: 'Paneer Do Pyaza', price: '280', offer: '20', org: '350', type: 'veg' , img: 'https://cdn3.foodviva.com/static-content/food-images/curry-recipes/paneer-do-pyaza-recipe/paneer-do-pyaza-recipe.jpg' },
      { id: 5, name: 'Shahi Paneer', price: '280', offer: '15', org: '325', type: 'veg' , img: 'https://myfoodstory.com/wp-content/uploads/2022/04/Shahi-Paneer-1.jpg' },
      { id: 6, name: 'Paneer Mirch Masala', price: '280', offer: '12', org: '320', type: 'veg' , img: 'https://myannoyingopinions.files.wordpress.com/2021/12/paneer-mirch-masala-1.jpg?w=640' },
      { id: 7, name: 'Matar Paneer', price: '280', offer: '10', org: '310', type: 'veg' , img: 'https://t3.ftcdn.net/jpg/04/02/96/24/360_F_402962424_qBGTw25i52nmi8Yz7M6IbpEev602CQts.jpg' },
      { id: 8, name: 'Palak Paneer', price: '290', offer: '18', org: '355', type: 'veg' , img: 'https://thumbs.dreamstime.com/b/tasty-indian-dish-palak-paneer-served-white-bowl-82240588.jpg' },
      { id: 9, name: 'Paneer Butter Masala', price: '290', offer: '15', org: '340', type: 'veg' , img: 'https://vegecravings.com/wp-content/uploads/2017/04/paneer-butter-masala-recipe-step-by-step-instructions.jpg.webp' },
      { id: 10, name: 'Paneer Peshwari', price: '280', offer: '20', org: '350', type: 'veg' , img: 'https://www.archanaskitchen.com/images/archanaskitchen/1-Author/nithya.anantham/Paneer_Peshawari_Recipe.jpg' },
      { id: 11, name: 'Paneer Pasanda', price: '290', offer: '15', org: '340', type: 'veg' , img: 'https://hungryforever.net/wp-content/uploads/2015/06/paneer-pasanda-recipe.jpg' },
      { id: 12, name: 'Paneer Bhurji', price: '280', offer: '12', org: '320', type: 'veg' , img: 'https://cdn1.foodviva.com/static-content/food-images/curry-recipes/paneer-bhurji-recipe/paneer-bhurji-recipe.jpg' },
      { id: 13, name: 'Paneer Takatak', price: '290', offer: '10', org: '320', type: 'veg' , img: 'https://www.sanjeevkapoor.com/UploadFiles/RecipeImages/PANEER-TAKA-TAK---skk.jpg' },
      { id: 14, name: 'Paneer Tawa Masala', price: '320', offer: '18', org: '390', type: 'veg' , img: 'https://www.sanjeevkapoor.com/UploadFiles/RecipeImages/PANEER-TAWA-MASALA---skk.jpg' },
      { id: 15, name: 'Paneer Tikka Masala', price: '320', offer: '15', org: '375', type: 'veg' , img: 'https://cookingfromheart.com/wp-content/uploads/2017/03/Paneer-Tikka-Masala-4.jpg' },
      { id: 16, name: 'Paneer Delicious', price: '320', offer: '12', org: '365', type: 'veg' , img: 'https://media.istockphoto.com/id/1354042949/photo/curd-paneer-masala.webp?b=1&s=170667a&w=0&k=20&c=StDFC_Jvpy1v41mHE8PEd9vk-qYt3kkjkwPue8J32Mo=' },
      // Add more Paneer dishes as needed
    ]
  },
  {
    category: 'Mini Meals',
    products: [
      { id: 1, name: 'Kadhai Paneer + Mini Lachha Paratha Mini Naan (2 Pc)', price: '199', offer: '10', org: '220', type: 'veg' , img: 'https://media-cdn.tripadvisor.com/media/photo-s/17/57/d9/5a/kadhai-paneer-and-lachha.jpg' },
      { id: 2, name: 'Kadhai Paneer + Steam Rice / Jeera Rice', price: '199', offer: '15', org: '235', type: 'veg' , img: 'https://d3gy1em549lxx2.cloudfront.net/2f0b4b10-6b2e-4c95-b085-2a28fbb3ff9b.jpg' },
      { id: 3, name: 'Paneer Butter Masala + Mini Naan / Mini Luchha (2 Pc)', price: '199', offer: '12', org: '225', type: 'veg' , img: 'https://1.bp.blogspot.com/-bZqeY5iUcS8/YBmddUdH-GI/AAAAAAAAZdI/e7OoQC6N0D0ioliJHiVAcR8DbF9uvXURQCLcBGAsYHQ/s2048/paneer%2Bbutter%2Bmasala%2B15.JPG' },
      { id: 4, name: 'Dal Tadka With Jeera Rice / Plain Rice / (2 Pc) Bread', price: '180', offer: '18', org: '220', type: 'veg' , img: 'https://img.freepik.com/premium-photo/whole-yellow-lentil-dal-tadka-fry-with-jeera-rice-selective-focus_466689-14825.jpg' },
      { id: 5, name: 'Dal Makhani With Jeera Rice / Plain Rice / (2Pc) Bread', price: '199', offer: '15', org: '235', type: 'veg' , img: 'https://img-global.cpcdn.com/recipes/acc289e1a1c5aa03/680x482cq70/dal-makhani-with-jeera-rice-recipe-main-photo.jpg' },
      { id: 6, name: 'Paneer Chilly With Veg Noodle / Veg Fried Rice', price: '180', offer: '10', org: '200', type: 'veg' , img: 'https://previews.123rf.com/images/indianfoodimages/indianfoodimages1907/indianfoodimages190702531/127154173-assorted-indo-chinese-dishes-in-group-includes-schezwan-szechuan-hakka-noodles-veg-fried-rice-veg.jpg' },
      { id: 7, name: 'Chhola With Mini Naan / Mini Kulcha (2Pc)', price: '170', offer: '12', org: '195', type: 'veg' , img: 'https://t4.ftcdn.net/jpg/02/95/09/39/360_F_295093993_O8Bab6DsZiQvs4PRLUtxtMK28EZ2u4M8.jpg' },
      { id: 8, name: 'Veg Manchurian With Veg Fried Rice / Veg Noodle', price: '170', offer: '10', org: '190', type: 'veg' , img: 'https://img.freepik.com/premium-photo/veg-manchurian-fried-rice-made-fried-mixed-vegetables-balls-along-with-rice-is-tossed-soy-tomato-based-sauce-indo-chinese-recipe-selective-focus_726363-422.jpg' },
      { id: 9, name: 'Paneer Handi With Mini Naan / Mini Lachha (2Pc)', price: '199', offer: '18', org: '245', type: 'veg' , img: 'https://thumbs.dreamstime.com/b/handi-paneer-gravy-dish-liquified-blended-mixture-tomatoes-green-chillies-ginger-other-ingredients-addition-249378444.jpg' },
      { id: 10, name: 'Paneer Tawa Masala With Mini Naan / Missi Roti (2 Pc)', price: '230', offer: '15', org: '270', type: 'veg' , img: 'https://b.zmtcdn.com/data/dish_photos/aa7/107f0e912eabeff50c73c791ddf97aa7.jpg' },
      // Add more Mini Meals as needed
    ]
  },
  {
    category: 'Kofta and Aloo Dishes',
    products: [
      { id: 1, name: 'Veg Kofta', price: '270', offer: '15', org: '315', type: 'veg' , img: 'https://media.istockphoto.com/id/1219174099/photo/malai-kofta-curry-in-black-bowl-at-dark-slate-background-malai-kofta-is-indian-cuisine-dish.jpg?s=612x612&w=0&k=20&c=ZqajoGuZIjQ_37LU7ZDyRpCJTCuLctwClGVumH2xCfM=' },
      { id: 2, name: 'Malai Kofta', price: '290', offer: '12', org: '330', type: 'veg' , img: 'https://media.istockphoto.com/id/1203164861/photo/malai-kofta-famous-indian-cuisine.jpg?s=612x612&w=0&k=20&c=qTtlFnsUP29gfI46Hz9X81sbs789xFiE8YpR9udhzOA=' },
      { id: 3, name: 'Nargisi Kofta', price: '280', offer: '10', org: '310', type: 'veg' , img: 'https://english.ent24x7.com/static/c1e/client/81305/uploaded/02f3886faa08840758d40e645c624e2e.png' },
      { id: 4, name: 'Aloo Dum Banaras', price: '220', offer: '18', org: '270', type: 'veg' , img: 'https://www.chezshuchi.com/images/banarsi-dum-aloo11.JPG' },
      { id: 5, name: 'Aloo Dum Kashmiri', price: '230', offer: '15', org: '270', type: 'veg' , img: 'https://www.sawadindiaka.in/wp-content/uploads/2023/01/Kashmiri-Dum-Aloo-Ready.jpg' },
      { id: 6, name: 'Aloo Dum Lucknowi', price: '220', offer: '12', org: '250', type: 'veg' , img: 'https://www.shutterstock.com/image-photo/tasty-dum-aloo-whole-potatoes-600nw-1923525176.jpg' },
      { id: 7, name: 'Kofta & Aloo', price: '220', offer: '10', org: '245', type: 'veg' , img: 'https://thumbs.dreamstime.com/b/malai-kofta-curry-black-bowl-dark-slate-background-indian-cuisine-dish-food-potato-paneer-cheese-deep-fried-179415022.jpg' },
      // Add more Kofta and Aloo dishes as needed
    ]
  },
  {
    category: 'Dal Dishes',
    products: [
      { id: 1, name: 'Dal Fry', price: '169', offer: '12', org: '190', type: 'veg' , img: 'https://t4.ftcdn.net/jpg/02/28/14/71/360_F_228147186_LNqGgB8OnPKF06475mvJM9s6MSUy65GI.jpg' },
      { id: 2, name: 'Dal Tadka', price: '179', offer: '15', org: '210', type: 'veg' , img: 'https://thumbs.dreamstime.com/b/delicious-dal-tadka-recipe-yellow-lentils-spices-herbs-chili-pepper-close-up-bowl-table-horizontal-127705140.jpg' },
      { id: 3, name: 'Dal Makhani', price: '199', offer: '10', org: '220', type: 'veg' , img: 'https://media.istockphoto.com/id/1170374719/photo/dal-makhani-at-dark-background.jpg?s=612x612&w=0&k=20&c=49yLaUAE2apakVk2AAiRQimZd98WtSjIQ0hzCzWsmns=' },
      { id: 4, name: 'Dal Punchmail', price: '179', offer: '18', org: '220', type: 'veg' , img: 'https://i0.wp.com/vegecravings.com/wp-content/uploads/2020/01/Panchmel-Dal-Recipe-Step-By-Step-Instructions-scaled.jpg?fit=2560%2C2027&quality=65&strip=all&ssl=1' },
      { id: 5, name: 'Dal Bukhara', price: '179', offer: '15', org: '210', type: 'veg' , img: 'https://www.archanaskitchen.com/images/archanaskitchen/1-Author/Smitha-Kalluraya/Dal_bukhara_Recipe.jpg' },
      { id: 6, name: 'Dal Special', price: '179', offer: '12', org: '205', type: 'veg' , img: 'https://t3.ftcdn.net/jpg/02/49/13/46/360_F_249134675_sn1RWCs0xY4DHNmtbbOjRp7HxLdViCXR.jpg' },
      // Add more Dal dishes as needed
    ]
  },
  {
    category: 'Indian Main Course Non-Veg',
    products: [
      { id: 1, name: 'Chicken Masala (HALF)', price: '299/-', offer: '15', org: '350', type: 'non-veg' , img: 'https://www.shutterstock.com/image-photo/spicy-red-chicken-curry-goan-600nw-2065708913.jpg' },
      { id: 2, name: 'Chicken Masala (FULL)', price: '589/-', offer: '15', org: '690', type: 'non-veg' , img: 'https://img.freepik.com/premium-photo/chicken-curry-masala-kerala-style-chicken-curry-using-fried-coconut-traditional-way_527904-1716.jpg' },
      { id: 3, name: 'Butter Chicken (HALF)', price: '319/-', offer: '12', org: '365', type: 'non-veg' , img: 'https://t3.ftcdn.net/jpg/06/01/41/66/360_F_601416673_Tn9dqtXuujNiavuJnNNspgcDezsStYpD.jpg' },
      { id: 4, name: 'Butter Chicken (FULL)', price: '629/-', offer: '12', org: '720', type: 'non-veg' , img: 'https://www.shutterstock.com/image-photo/tasty-butter-chicken-curry-dish-600nw-1153329478.jpg' },
      { id: 5, name: 'Chicken Korma (HALF)', price: '299/-', offer: '10', org: '330', type: 'non-veg' , img: 'https://t3.ftcdn.net/jpg/02/29/39/34/360_F_229393457_LGaJHVGBn04k84lX5tuJ9bi1FQhffUxq.jpg' },
      { id: 6, name: 'Chicken Korma (FULL)', price: '589/-', offer: '10', org: '650', type: 'non-veg' , img: 'https://www.shutterstock.com/image-photo/korma-chicken4-600nw-223265599.jpg' },
      { id: 7, name: 'Chicken Peshwari (HALF)', price: '280/-', offer: '15', org: '320', type: 'non-veg' , img: 'https://i0.wp.com/jayeetacha.com/wp-content/uploads/2015/07/img_5640.jpg?ssl=1' },
      { id: 8, name: 'Chicken Peshwari (FULL)', price: '590/-', offer: '20', org: '720', type: 'non-veg' , img: 'https://b.zmtcdn.com/data/dish_photos/737/eb6d1b1478c6ae92eea321e50af17737.jpg' },
      { id: 9, name: 'Chicken Kalimirch (HALF)', price: '310/-', offer: '18', org: '380', type: 'non-veg' , img: 'https://thechefkitchen.in/wp-content/uploads/2022/05/Thechefkitchen-Chicken-Kali-Mirch.jpg' },
      { id: 10, name: 'Chicken Kalimirch (FULL)', price: '630/-', offer: '15', org: '750', type: 'non-veg' , img: 'https://d3kgrlupo77sg7.cloudfront.net/media/l3-meats.shopnix.org/images/products/nph0065.20181124110210.webp' },
      { id: 11, name: 'Rara Chicken (HALF)', price: '299/-', offer: '10', org: '330', type: 'non-veg' , img: 'https://madscookhouse.com/wp-content/uploads/2022/05/Chicken-Rara-Masala.jpg' },
      { id: 12, name: 'Rara Chicken (FULL)', price: '599/-', offer: '10', org: '660', type: 'non-veg' , img: 'https://madscookhouse.com/wp-content/uploads/2022/05/Chicken-Rara-Masala.jpg' },
      { id: 13, name: 'Chicken Do Pyaza (HALF)', price: '299/-', offer: '15', org: '350', type: 'non-veg' , img: 'https://www.yummyfoodanddiet.com/wp-content/uploads/2022/04/Amazing-Chicken-Do-Pyaza-Recipe.png' },
      { id: 14, name: 'Chicken Do Pyaza (FULL)', price: '599/-', offer: '15', org: '710', type: 'non-veg' , img: 'https://maunikagowardhan.co.uk/wp-content/uploads/2017/12/IMG_9563-700x467.jpg' },
      { id: 15, name: 'Murg Labadar (HALF)', price: '310/-', offer: '12', org: '355', type: 'non-veg' , img: 'https://i.redd.it/b9jxk0ur9q071.jpg' },
      { id: 16, name: 'Murg Labadar (FULL)', price: '599/-', offer: '12', org: '680', type: 'non-veg' , img: 'https://i.redd.it/b9jxk0ur9q071.jpg' },
      { id: 17, name: 'Chicken Korma (Awadhi) (HALF)', price: '289/-', offer: '10', org: '320', type: 'non-veg' , img: 'https://img-global.cpcdn.com/recipes/2746815c0d6ccd91/680x482cq70/awadhi-chicken-korma-recipe-main-photo.jpg' },
      { id: 18, name: 'Chicken Korma (Awadhi) (FULL)', price: '589/-', offer: '10', org: '650', type: 'non-veg' , img: 'https://theolddelhi.com/wp-content/uploads/2021/08/download-6.jpg' },
      { id: 19, name: 'Chicken Achari Masala (HALF)', price: '289/-', offer: '15', org: '340', type: 'non-veg' , img: 'https://glebekitchen.com/wp-content/uploads/2020/10/acharichickentopbowlserve.jpg' },
      { id: 20, name: 'Chicken Achari Masala (FULL)', price: '589/-', offer: '15', org: '690', type: 'non-veg' , img: 'https://i0.wp.com/kalimirchbysmita.com/wp-content/uploads/2015/06/Achari-Murg-02.jpg' },
      { id: 21, name: 'Chicken Dehati (HALF)', price: '289/-', offer: '12', org: '330', type: 'non-veg' , img: 'https://pbs.twimg.com/media/EctVMzmU0AAGo21.jpg' },
      { id: 22, name: 'Chicken Dehati (FULL)', price: '589/-', offer: '12', org: '670', type: 'non-veg' , img: 'https://pbs.twimg.com/media/EctVMzmU0AAGo21.jpg' },
      { id: 23, name: 'Chicken Kolhapuri (HALF)', price: '289/-', offer: '10', org: '320', type: 'non-veg' , img: 'https://www.yummytummyaarthi.com/wp-content/uploads/2015/07/1-46.jpg' },
      { id: 24, name: 'Chicken Kolhapuri (FULL)', price: '589/-', offer: '10', org: '650', type: 'non-veg' , img: 'https://www.indrani-will-teach.com/wp-content/uploads/2014/12/DSC02278.jpg' },
      { id: 25, name: 'Chicken Stew (HALF)', price: '289/-', offer: '15', org: '340', type: 'non-veg' , img: 'https://www.budgetbytes.com/wp-content/uploads/2021/10/Chicken-Stew-close.jpg' },
      { id: 26, name: 'Chicken Stew (FULL)', price: '589/-', offer: '15', org: '690', type: 'non-veg' , img: 'https://www.littlebroken.com/wp-content/uploads/2022/03/Chicken-Stew-4.jpg' },
      { id: 27, name: 'Chicken Bhuna Gust (HALF)', price: '289/-', offer: '12', org: '330', type: 'non-veg' , img: 'https://static.toiimg.com/photo/83942719.cms' },
      { id: 28, name: 'Chicken Bhuna Gust (FULL)', price: '589/-', offer: '12', org: '670', type: 'non-veg' , img: 'https://www.palatesdesire.com/wp-content/uploads/2020/10/Bhuna_chicken_fry@Palates_desire-1024x683.jpg' },
      { id: 29, name: 'Chicken Champaran (HALF)', price: '289/-', offer: '10', org: '320', type: 'non-veg' , img: 'https://www.foodie-trail.com/wp-content/uploads/2023/05/20230428_173117046_iOS-scaled.jpg' },
      { id: 30, name: 'Chicken Champaran (FULL)', price: '589/-', offer: '10', org: '650', type: 'non-veg' , img: 'https://www.foodie-trail.com/wp-content/uploads/2023/05/20230428_173117046_iOS-scaled.jpg' },
      { id: 31, name: 'Kadaknath Chicken (HALF)', price: '600/-', offer: '18', org: '730', type: 'non-veg' , img: 'https://static.toiimg.com/photo/74330873.cms' },
      { id: 32, name: 'Kadaknath Chicken (FULL)', price: '1050/-', offer: '15', org: '1230', type: 'non-veg' , img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZDgxmL9LirzvDMgI1RKfqGhU2lVFLNKkT8VWb-ohjqA&s' },
      // Add the rest of the products here
    ]
  },
  {
    category: 'Eggs',
    products: [
      { id: 1, name: 'Egg Curry (2 pc)', price: '129/-', offer: '10%', org: '150/-', type: 'egg' , img: 'https://www.yummytummyaarthi.com/wp-content/uploads/2015/02/1-23.jpg' },
      { id: 2, name: 'Egg Masala (2 pc)', price: '129/-', offer: '8%', org: '140/-', type: 'egg' , img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0rm-SZqJ4rTz8UwYpwRNi7AhCRvtdAgXqFp5-X_bKmg&s' },
      { id: 3, name: 'Egg Bhurji (2 pc)', price: 'N/A', offer: 'N/A', org: 'N/A', type: 'egg' , img: 'https://qph.cf2.quoracdn.net/main-qimg-18c82991f4ceb2f36bcc2855ac169fc0-lq' },
      { id: 4, name: 'Eggs (3 pc)', price: '199/-', offer: '15%', org: '230/-', type: 'egg' , img: 'https://www.simplyrecipes.com/thmb/-MGi_bdiI4pWLZ2PYKSPHfZdlD4=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/__opt__aboutcom__coeus__resources__content_migration__simply_recipes__uploads__2017__04__2017-04-11-Pressure-Cooker-Hardboiled-Eggs-16-a3e282f2cf9949d2a522fc488929d31c.jpg' },
      { id: 5, name: 'Eggs (4 pc)', price: '199/-', offer: '12%', org: '210/-', type: 'egg' , img: 'https://www.simplyrecipes.com/thmb/-MGi_bdiI4pWLZ2PYKSPHfZdlD4=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/__opt__aboutcom__coeus__resources__content_migration__simply_recipes__uploads__2017__04__2017-04-11-Pressure-Cooker-Hardboiled-Eggs-16-a3e282f2cf9949d2a522fc488929d31c.jpg' },
    ],
  },
  {
    category: 'Bater Special',
    products: [
      { id: 1, name: 'Bater Masala (2 pc)', price: '380/-', offer: '18%', org: '450/-', type: 'non-veg' , img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRFGj68vVQM-uThDksWQDL6jRYl5N2yzWGKZl7CU3j5pg&s' },
      { id: 2, name: 'Bater Gravy (2 pc)', price: '380/-', offer: '20%', org: '480/-', type: 'non-veg' , img: 'https://i0.wp.com/kalimirchbysmita.com/wp-content/uploads/2016/09/Kolhapuri-Bater-Masala-01.jpg' },
      { id: 4, name: 'Bater Masala (4 pc)', price: '650/-', offer: '15%', org: '750/-', type: 'non-veg' , img: 'https://i0.wp.com/kalimirchbysmita.com/wp-content/uploads/2016/09/Kolhapuri-Bater-Masala-02-1024x618.jpg?resize=1024%2C618' },
      { id: 5, name: 'Bater Gravy (4) pc)', price: '650/-', offer: '10%', org: '700/-', type: 'non-veg' , img: 'https://2.bp.blogspot.com/-s4qcSdkBEEY/VA0PMLtRnmI/AAAAAAAAHzI/YrAvyhZKi0E/s1600/IMG_6309.JPG' },
    ],
  },
  {
    category: 'Mutton In Gravy',
    products: [
      { id: 1, name: 'Mutton Handi (HALF)', price: '380/-', offer: '25%', org: '500/-', type: 'non-veg' , img: 'https://lh4.googleusercontent.com/proxy/5Xj3XiJeX0WF7oawcaRifhl9NglSU4EdIeUFu12RhyA2UekBlYyFr99m-keFRz5gxFgGK-7jm4lZ73hYBRyif0HjIwva' },
      { id: 2, name: 'Mutton Handi (FULL)', price: '730/-', offer: '22%', org: '900/-', type: 'non-veg' , img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7X-7AiScRxqiSfnxLoyeXK-MJHiDoHXeJU1Hae4L4Lw&s' },
      { id: 3, name: 'Mutton Rogan Josh (HALF)', price: '380/-', offer: '20%', org: '480/-', type: 'non-veg' , img: 'https://www.ruchifoodline.com/recipes//cdn/recipes/mutton-rogan-josh.jpg' },
      { id: 4, name: 'Mutton Rogan Josh (FULL)', price: '730/-', offer: '18%', org: '850/-', type: 'non-veg' , img: 'https://2.bp.blogspot.com/--qSXjvPqwlk/WNIdQmvaN6I/AAAAAAAADAg/h0j9hytktVY2lFI_AuhBR1fE7UkfK3t0gCLcB/s1600/Mutton%2BRogan%2BJosh%2BRecipe.JPG' },
      { id: 5, name: 'Mutton Bhuna Gost (HALF)', price: '380/-', offer: '15%', org: '450/-', type: 'non-veg' , img: 'https://spiceeats.com/wp-content/uploads/2020/07/Bhuna-Mutton.jpg' },
      { id: 6, name: 'Mutton Bhuna Gost (FULL)', price: '730/-', offer: '12%', org: '800/-', type: 'non-veg' , img: 'https://2.bp.blogspot.com/-Q-6UAXIK1uw/W6hxiABh0tI/AAAAAAAAHLo/BDhDmrsDcawsyGlt8PE4US7vczYY6U0-wCLcBGAs/s1600/IMG_3692.jpg' },
      { id: 7, name: 'Mutton Rara (HALF)', price: '380/-', offer: '10%', org: '420/-', type: 'non-veg' , img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRS280-FNbfWINdm8Lw-omWuYXjRUU2PnDFu1xXjQDwbA&s' },
      { id: 8, name: 'Mutton Rara (FULL)', price: '730/-', offer: '8%', org: '700/-', type: 'non-veg' , img: 'https://media-cdn.tripadvisor.com/media/photo-m/1280/18/7a/cc/ff/mutton-rara.jpg' },
      { id: 9, name: 'Mutton Do Pyaza (HALF)', price: '380/-', offer: '12%', org: '450/-', type: 'non-veg' , img: 'https://www.archanaskitchen.com/images/archanaskitchen/1-Author/Pooja_Nadkarni/Mutton_Do_Pyaza_1.jpg' },
      { id: 10, name: 'Mutton Do Pyaza (FULL)', price: '730/-', offer: '10%', org: '800/-', type: 'non-veg' , img: 'https://static.toiimg.com/thumb/75557706.cms?width=573&height=430' },
      { id: 11, name: 'Mutton Sag Wala (HALF)', price: '380/-', offer: '15%', org: '500/-', type: 'non-veg' , img: 'https://food-images.files.bbci.co.uk/food/recipes/mutton_saag_25343_16x9.jpg' },
      { id: 12, name: 'Mutton Sag Wala (FULL)', price: '730/-', offer: '18%', org: '900/-', type: 'non-veg' , img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTt1aXyvcA3DDay6porEITke38h5my2ZeZzw1KqBXC34Q&s' },
      { id: 13, name: 'Bhuna Keema (HALF)', price: '380/-', offer: '20%', org: '480/-', type: 'non-veg' , img: 'https://kfoods.com/images1/newrecipeicon/bhuna-keema-and-paratha_13414.jpg' },
      { id: 14, name: 'Bhuna Keema (FULL)', price: '730/-', offer: '22%', org: '850/-', type: 'non-veg' , img: 'https://www.ruchikrandhap.com/wp-content/uploads/2018/07/Bhuna-Keema-2-2.jpg' },
      { id: 15, name: 'Mutton Stew (HALF)', price: '380/-', offer: '25%', org: '500/-', type: 'non-veg' , img: 'https://www.thespicegenie.com/wp-content/uploads/2019/12/Mutton-Stew-rotated.jpg' },
      { id: 16, name: 'Mutton Stew (FULL)', price: '730/-', offer: '20%', org: '800/-', type: 'non-veg' , img: 'https://static.punjabkesari.in/multimedia/2023_6image_11_18_16726973487_mutton-stew.jpg' },
      { id: 17, name: 'Boti Kebab (HALF)', price: '390/-', offer: '15%', org: '450/-', type: 'non-veg' , img: 'https://www.foodandwine.com/thmb/eE_U_Xkuw3xtOMoT-rMEmiyrXKo=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/Lamb-Boti-Kebabs-FT-RECIPE0622-2efbc6efd2884831b1bb293dc7acb5cc.jpg' },
      { id: 18, name: 'Boti Kebab (FULL)', price: '730/-', offer: '10%', org: '700/-', type: 'non-veg' , img: 'https://www.thespruceeats.com/thmb/d3dydMavjnQGJh1dsCsPzNkkrB4=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/tikka-boti-kabobs-333771-Hero_01-ddffd2bce19a42138c26415446022280.jpg' },
      { id: 19, name: 'Mutton Nehari (HALF)', price: '380/-', offer: '12%', org: '480/-', type: 'non-veg' , img: 'https://t4.ftcdn.net/jpg/00/29/29/25/360_F_29292582_rKWLOVhtxYcFgAkSK7S7dqyxXcXodpJK.jpg' },
      { id: 20, name: 'Mutton Nehari (FULL)', price: '730/-', offer: '18%', org: '900/-', type: 'non-veg' , img: 'https://www.potsandpans.in/cdn/shop/articles/Mutton_Nihari.jpg?v=1558508107' },
      { id: 21, name: 'Mutton Kaleji (HALF)', price: '380/-', offer: '20%', org: '480/-', type: 'non-veg' , img: 'https://img.freepik.com/premium-photo/mutton-liver-fry-kaleji-masala-popular-non-vegetarian-recipe-from-india-pakistan_466689-46982.jpg' },
      { id: 22, name: 'Mutton Kaleji (FULL)', price: '730/-', offer: '15%', org: '750/-', type: 'non-veg' , img: 'https://t3.ftcdn.net/jpg/02/41/50/04/360_F_241500424_e4Ce2vRuA4X2SFpUNxUcFZlPGYchqqSL.jpg' },
      { id: 23, name: 'Keema Kaleji (HALF)', price: '380/-', offer: '10%', org: '420/-', type: 'non-veg' , img: 'https://ik.imagekit.io/dunzo/tr:w-$w$,h-$h$,cm-pad_resize/OXFXNWt6WDE2Yk9JdDdQcnU5bElWdz09-product_image.jpg' },
      { id: 24, name: 'Keema Kaleji (FULL)', price: '730/-', offer: '8%', org: '700/-', type: 'non-veg' , img: 'https://static.toiimg.com/photo/75543270.cms' },
      { id: 25, name: 'Mutton Rizwala (HALF)', price: '380/-', offer: '12%', org: '450/-', type: 'non-veg' , img: 'https://www.archanaskitchen.com/images/archanaskitchen/1-Author/shaheen_ali/Mutton_Rezala_.jpg' },
      { id: 26, name: 'Mutton Rizwala (FULL)', price: '730/-', offer: '10%', org: '800/-', type: 'non-veg' , img: 'https://yummraj.com/wp-content/uploads/2012/10/img_0114.jpg?w=300' },
    ],
  },
  {
    category: 'Breads',
    products: [
      { id: 1, name: 'Tawa Roti', price: '15', offer: '5', org: '20', type: 'veg', img: 'https://salasdaily.com/cdn/shop/products/tawa_roti.webp?v=1667534398' },
      { id: 2, name: 'Tawa Roti Butter', price: '20', offer: '8', org: '22', type: 'veg', img: 'https://www.jagghoomiya.com/jg_uploads/2022/01/Tawa-Roti-Butter.jpg' },
      { id: 3, name: 'Tandoori Roti', price: '25', offer: '10', org: '28', type: 'veg', img: 'https://media.istockphoto.com/id/1150376593/photo/bread-tandoori-indian-cuisine.jpg?s=612x612&w=0&k=20&c=GGT5LN7G4zLhJTEnP_KcyvYuayi8f1nJcvQwvmj0rCM=' },
      { id: 4, name: 'Tandoori Butter', price: '30', offer: '12', org: '34', type: 'veg', img: 'https://t4.ftcdn.net/jpg/02/52/31/93/360_F_252319323_I4hRMLLqmAs2SF6OcSOF5AFlWhMVGbgP.jpg' },
      { id: 5, name: 'Rumali Roti', price: '15', offer: '7', org: '18', type: 'veg', img: 'https://i0.wp.com/cookingfromheart.com/wp-content/uploads/2016/07/CH_DSC_0044_4.jpg?resize=720%2C494&ssl=1' },
      { id: 6, name: 'Plain Naan', price: '40', offer: '15', org: '45', type: 'veg', img: 'https://admin.bagsy.in/storage/34994/53338316.cms' },
      { id: 7, name: 'Butter Naan', price: '50', offer: '20', org: '55', type: 'veg', img: 'https://www.shutterstock.com/image-photo/indian-naan-bread-herbs-garlic-600nw-2255100041.jpg' },
      { id: 8, name: 'Garlic Naan', price: '60', offer: '25', org: '65', type: 'veg', img: 'https://t3.ftcdn.net/jpg/01/71/17/02/360_F_171170289_WnnpOeTro0XbmoJzLmUZVLDgigoxWd2t.jpg' },
      { id: 9, name: 'Lachha Paratha', price: '45', offer: '18', org: '55', type: 'veg', img: 'https://myfoodstory.com/wp-content/uploads/2022/08/Homemade-Lachha-Paratha-3.jpg' },
      { id: 10, name: 'Missi Roti', price: '45', offer: '15', org: '50', type: 'veg', img: 'https://static.toiimg.com/thumb/54504896.cms?width=1200&height=900' },
      { id: 11, name: 'Onion Kulcha', price: '59', offer: '22', org: '60', type: 'veg', img: 'https://www.archanaskitchen.com/images/archanaskitchen/1-Author/Hina_Gujral/Whole_Wheat_Onion_Kulcha_Recipe.jpg' },
      { id: 12, name: 'Paneer Kulcha', price: '89', offer: '8', org: '10', type: 'veg', img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSkOr4OaTSQU5vJ3jKSU9oFFOdXbIm79sP43JfxMzW65g&s' },
      // ... Add more items if needed
    ],
  },
  {
    category: 'Salads and Raita',
    products: [
      { id: 1, name: 'Boodi Raita', price: '80', offer: '10', org: '90', type: 'veg', img: 'https://traditionallymodernfood.com/wp-content/uploads/2021/05/boondi-raita-boondi-ka-raita-scaled.jpeg' },
      { id: 2, name: 'Mix Raita', price: '90', offer: '15', org: '105', type: 'veg', img: 'https://yummyies.com/wp-content/uploads/2021/04/MIX-VEG-RAITA.jpg' },
      { id: 3, name: 'Pineapple Raita', price: '99', offer: '12', org: '111', type: 'veg', img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0LcTw9x8BEpkiQSicEwhEnkvttj-UIXfAvvOTPr0I3w&s' },
      { id: 4, name: 'Fruit Raita', price: '120', offer: '20', org: '140', type: 'veg', img: 'https://staticimg.amarujala.com/assets/images/2019/03/22/750x506/_1553247297.jpeg' },
      { id: 5, name: 'Plain Curd', price: '79', offer: '8', org: '87', type: 'veg', img: 'https://www.shutterstock.com/image-photo/homemade-yogurt-indian-dahi-curd-600nw-2277907151.jpg' },
      { id: 6, name: 'Green Salad', price: '79', offer: '10', org: '89', type: 'veg', img: 'https://img.freepik.com/free-photo/salad-from-tomatoes-cucumber-red-onions-lettuce-leaves-healthy-summer-vitamin-menu-vegan-vegetable-food-vegetarian-dinner-table-top-view-flat-lay_2829-6482.jpg' },
      { id: 7, name: 'Onion Salad', price: '59', offer: '7', org: '66', type: 'veg', img: 'https://www.indianhealthyrecipes.com/wp-content/uploads/2022/07/onion-salad-recipe.jpg' },
      { id: 8, name: 'Cucumber Salad', price: '69', offer: '9', org: '78', type: 'veg', img: 'https://i2.wp.com/www.downshiftology.com/wp-content/uploads/2019/05/Cucumber-Salad-main-1.jpg' },
      { id: 9, name: 'Papad (2 Pc) Dry/Fry', price: '49', offer: '5', org: '54', type: 'veg', img: 'https://flybuy.in/wp-content/uploads/2020/06/papapd-fry.png' },
      { id: 10, name: 'Masal Papad (2 Pc)', price: '70', offer: '12', org: '82', type: 'veg', img: 'https://thumbs.dreamstime.com/b/masala-papad-delicious-indian-snack-made-using-roasted-fried-topped-tangy-spicy-onion-tomato-mix-served-over-224002476.jpg' },
      // ... Add more items if needed
    ],
  },
  {
    category: 'Rice and Biryani',
    products: [
      { id: 1, name: 'Murg Dum Biryani (2pc)', price: '149', offer: '20', org: '169', type: 'non-veg', img: 'https://www.mystore.in/s/62ea2c599d1398fa16dbae0a/64a01845ce44d3e623107733/atr4euwvb2yv8hwehklv6udizfy8_thyknl2n3uhqzhzuq1ioumtwhqwqbzf7e26ggrf-lqqygoxkvbdt-58w3dldio6gewp3ybgwapfzg.jpg' },
      { id: 2, name: 'Mutton Dum Biryani (2pc)', price: '179', offer: '25', org: '204', type: 'non-veg', img: 'https://media.istockphoto.com/id/1407172002/photo/indian-spicy-mutton-biryani-with-raita-and-gulab-jamun-served-in-a-dish-side-view-on-grey.jpg?s=612x612&w=0&k=20&c=sYldtF2E_cSuYioPtcmM15arsnSs2mIgpuAKUDuuGoI=' },
      { id: 3, name: 'Egg Dum Biryani (2pc)', price: '139', offer: '18', org: '157', type: 'non-veg', img: 'https://t3.ftcdn.net/jpg/05/19/56/64/360_F_519566490_LVXp9vFgu7rZ1u5elG2Wpdblm7s8m0xx.jpg' },
      { id: 4, name: 'Veg Dum Biryani', price: '149', offer: '15', org: '164', type: 'veg', img: 'https://kannanskitchen.com/wp-content/uploads/2021/04/DSC_1079_1.jpg' },
      { id: 5, name: 'Steam Rice', price: '120', offer: '10', org: '130', type: 'veg', img: 'https://img-global.cpcdn.com/recipes/f169270b52fae604/680x482cq70/steamed-rice-recipe-main-photo.jpg' },
      { id: 6, name: 'Jeera Rice', price: '130', offer: '12', org: '142', type: 'veg', img: 'https://myfoodstory.com/wp-content/uploads/2018/07/Perfect-Jeera-Rice-Indian-Cumin-Rice-4.jpg' },
      { id: 7, name: 'Veg Pulao', price: '139', offer: '14', org: '153', type: 'veg', img: 'https://www.recipehub.in/wp-content/uploads/2019/09/veg-pulao9website.jpg' },
      { id: 8, name: 'Peas Pulao', price: '139', offer: '15', org: '154', type: 'veg', img: 'https://theclubcafe.in/cdn/shop/products/Greenpeaspulao_658x.jpg?v=1621666153' },
      { id: 9, name: 'Paneer Pulao', price: '149', offer: '20', org: '169', type: 'veg', img: 'https://www.yummytummyaarthi.com/wp-content/uploads/2018/02/IMG_7078.jpg' },
      { id: 10, name: 'Kashmiri Pulao', price: '179', offer: '25', org: '204', type: 'veg', img: 'https://www.ndtv.com/cooks/images/kashmiri.pulao.jpg' },
      // ... Add more items if needed
    ],
  },
  {
    category: 'Fish Dishes',
    products: [
      { id: 1, name: 'Fish Tikka (8pc)', price: '280', offer: '15', org: '295', type: 'non-veg', img: 'https://b.zmtcdn.com/data/dish_photos/0c6/eaa00fe07290d9c7ce0661f8b261b0c6.png' },
      { id: 2, name: 'Fish Fry (4pc)', price: '260', offer: '12', org: '272', type: 'non-veg', img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZEtr5cFxUoaK8bwXO9rSFH3-n665Wj2MnJDvxxll6Mw&s' },
      { id: 3, name: 'Fish Curry (4pc)', price: '280', offer: '18', org: '298', type: 'non-veg', img: 'https://media.istockphoto.com/id/1295772368/photo/macher-jhol-in-black-bowl-on-dark-slate-table-top-indian-cuisine-bengali-fish-curry-asian.jpg?s=612x612&w=0&k=20&c=3asIIURIgisLwXAijZnmNY3p2EWEZEHzByjk7ke9xZk=' },
      { id: 4, name: 'Fish Finger', price: '260', offer: '10', org: '270', type: 'non-veg', img: 'https://t4.ftcdn.net/jpg/03/10/87/21/360_F_310872181_q0V3bRGXCSESD5D0lcVcZtTm5AaC4YJk.jpg' },
    ],
  },
  {
    category: 'Ice Creams',
    products: [
      { id: 1, name: 'Vanilla Single', price: '49', offer: '5', org: '54', type: 'veg', img: 'https://thumbs.dreamstime.com/b/scoop-vanilla-ice-cream-vanilla-beans-high-angle-still-life-single-served-white-background-bean-pods-fresh-leaf-88887157.jpg' },
      { id: 2, name: 'Vanilla Double', price: '80', offer: '10', org: '90', type: 'veg', img: 'https://previews.123rf.com/images/dusanzidar/dusanzidar1506/dusanzidar150600020/41060576-vanilla-ice-cream-on-a-plate-close-up.jpg' },
      { id: 3, name: 'Strawberry Single', price: '59', offer: '7', org: '66', type: 'veg', img: 'https://5.imimg.com/data5/VI/CP/GLADMIN-29028014/strawberry-single-scoop.png' },
      { id: 4, name: 'Strawberry Double', price: '99', offer: '12', org: '111', type: 'veg', img: 'https://c4.wallpaperflare.com/wallpaper/820/507/743/strawberry-ice-cream-hd-wallpaper-preview.jpg' },
      { id: 5, name: 'Mango Single', price: '59', offer: '8', org: '67', type: 'veg', img: 'https://media.istockphoto.com/id/162258940/photo/scoop-of-yellow-ice-cream.jpg?s=612x612&w=0&k=20&c=bvoYJmViXdYnch47-MYIa9yLYrDOQkFBuDqIwi3kcVM=' },
      { id: 6, name: 'Mango Double', price: '99', offer: '15', org: '114', type: 'veg', img: 'https://media.istockphoto.com/id/470731269/photo/scoops-of-mango-sorbet.jpg?s=612x612&w=0&k=20&c=PmVIej897BDhd_87bAN7jbf5MFzKq9hNmopLQFaPxSg=' },
      { id: 7, name: 'Chocolate Single', price: '65', offer: '6', org: '71', type: 'veg', img: 'https://www.shutterstock.com/image-photo/ice-cream-scoop-isolated-on-600nw-2290825613.jpg' },
      { id: 8, name: 'Chocolate Double', price: '110', offer: '12', org: '122', type: 'veg', img: 'https://therichrecipe.com/wp-content/uploads/2021/06/How-to-Make-Chocolate-Ice-Cream-with-Condensed-Milk.jpg' },
      { id: 9, name: 'Butter Scotch Single', price: '65', offer: '7', org: '72', type: 'veg', img: 'https://www.bigbasket.com/media/uploads/p/l/800446613_1-eat-o-gito-naturals-ice-cream-butterscotch.jpg' },
      { id: 10, name: 'Butter Scotch Double', price: '110', offer: '15', org: '125', type: 'veg', img: 'https://www.indianhealthyrecipes.com/wp-content/uploads/2022/03/butterscotch-ice-cream-recipe-500x375.jpg' },
      { id: 11, name: 'Kesar Pista Single', price: '65', offer: '8', org: '73', type: 'veg', img: 'https://www.mygingergarlickitchen.com/wp-content/rich-markup-images/1x1/1x1-kesar-pista-ice-cream.jpg' },
      { id: 12, name: 'Kesar Pista Double', price: '110', offer: '18', org: '128', type: 'veg', img: 'https://tiimg.tistatic.com/fp/1/002/084/kesar-pista-ice-cream-985.jpg' },
      { id: 13, name: 'Ice-Cream Single', price: '65', offer: '10', org: '75', type: 'veg', img: 'https://images.hindustantimes.com/img/2021/11/06/1600x900/e276cbaa-3e0e-11ec-a444-2c6d4ea9da0b_1636192223278.jpg' },
      { id: 14, name: 'Ice-Cream Double', price: '110', offer: '20', org: '130', type: 'veg', img: 'https://w.forfun.com/fetch/69/691f9bf65d0af66e0aaf9f9edfe0c4e2.jpeg' },
      // ... Add more items if needed
    ],
  },
  {
    category: 'Desserts',
    products: [
      { id: 1, name: 'Gulab Jamun (1pc)', price: '30', offer: '5', org: '54', type: 'veg', img: 'https://lh3.googleusercontent.com/kokS1LG3bMfIxz1jA-wkSG0FaswDZCCFSmiCISHY4u4MHCQfg-h8JYC-oK5kCIg3U7bwTEsvMc15ruyIXbn5qUWMW5lrm6K-Pn6YvdDo=w512-rw' },
      { id: 2, name: 'Gulab Jamun (2pc)', price: '49', offer: '5', org: '54', type: 'veg', img: 'https://media.istockphoto.com/id/521803129/photo/gulab-jamun-11.jpg?s=612x612&w=0&k=20&c=wyJaXY7Uu0hMBCXkcTDRaujSKN6Rp9roJeKDvF6CfHI=' },
      { id: 3, name: 'Rasgulla (1pc)', price: '30', offer: '5', org: '54', type: 'veg', img: 'https://lh3.googleusercontent.com/niYdVUxK7AIb6QQgwclVCLUaXjXZ8sRCqcpKphUAXHV2me9T2QGMft7UGVFBfiAUl4NiG8Wl5gMkJQUTmQvcYEPDiCabCvV3f6nd7OJG' },
      { id: 4, name: 'Rasgulla (2pc)', price: '49', offer: '5', org: '54', type: 'veg', img: 'https://www.mystore.in/s/62ea2c599d1398fa16dbae0a/6494e2cdf64e9bf40156dde8/ofwbkdaday9fmyxzny2pnxgjmt3dm8f3qqvavfxbspylpje2ycs7bn9aetj7imuibemyf8kgzkhly8tl9tmr82tpxgtoaivnvjesph1k-480x480.jpg' },
      { id: 3, name: 'Kulhad Kheer', price: '49', offer: '7', org: '66', type: 'veg', img: 'https://st2.depositphotos.com/5653638/11440/i/450/depositphotos_114403718-stock-photo-sweet-kheer-or-khir-payasa.jpg' },
      { id: 5, name: 'Shahi Tukda', price: '80', offer: '8', org: '67', type: 'veg', img: 'https://aartimadan.com/wp-content/uploads/2018/10/Shahi-Tukda-Recipe.jpg' },
    ]
  }
  
  

  
  
];


  const [activeCategory, setActiveCategory] = useState(categories[0]);
  const productRef = useRef(null);



  const sortProducts = (category) => {
    let sortedProducts;

    if (sortBy === 'veg') {
      sortedProducts = category.products.slice().sort((a, b) => a.type.localeCompare(b.type));
    } else if (sortBy === 'price') {
      sortedProducts = category.products.slice().sort((a, b) => {
        const priceA = parseFloat(a.price);
        const priceB = parseFloat(b.price);
        return priceA - priceB;
      });
    } else {
      sortedProducts = category.products.slice();
    }

    return {
      ...category,
      products: sortedProducts,
    };
  };

  const sortedAllProducts = allProducts.map(category => sortProducts(category));


  const handleProductScroll = () => {
    const productContainer = productRef.current;
    if (productContainer) {
      const scrollTop = productContainer.scrollTop;
      const productsWithOffsets = allProducts.map((item, index) => ({
        ...item,
        offsetTop: productContainer.querySelectorAll(`[data-category="${item.category}"]`)[0].offsetTop,
      }));
  
      const currentCategory = productsWithOffsets.reduce((prev, curr) => (
        Math.abs(curr.offsetTop - scrollTop) < Math.abs(prev.offsetTop - scrollTop) ? curr : prev
      ));
  
      setActiveCategory(currentCategory.category);
    }
  };
  
  const handleCategoryClick = (category) => {
    const productContainer = productRef.current;
    if (productContainer) {
      const categorySection = productContainer.querySelector(`[data-category="${category}"]`);
      
      if (categorySection) {
        const categoryOffset = categorySection.offsetTop - 470;
        productContainer.scrollTo({ top: categoryOffset, behavior: 'smooth' });
      }
    }
  };
  
  
  
  return (
    <Grid container justifyContent='space-between' >
      <Grid item md={2.8}  >
        <Paper className='deskcat' style={{ height: '100%', overflowY: 'auto' }}>
        {categories.map(category => (
          <Typography 
            key={category}
            className={`category-text ${activeCategory === category ? 'active' : ''}`}
            onClick={() => handleCategoryClick(category)}
          >
            {category}
          </Typography>
        ))}
        </Paper>

        <Paper className='mobcat' style={{ height: '100%', overflowY: 'auto' }}>
        {categories.map(category => (
          <Typography 
            key={category}
            className={`category-text ${activeCategory === category ? 'active' : ''}`}
            onClick={() => handleCategoryClick(category)}
          >
            {category}
          </Typography>
        ))}
        </Paper>
      </Grid>
      <Grid item md={9} className='productcont' >
        <Paper
           style={{
            height: '100%',
            overflowY: 'auto',
            scrollbarWidth: 'thin',
            WebkitOverflowScrolling: 'touch',
          }}
          ref={productRef}
          onScroll={handleProductScroll}
        >
          {sortedAllProducts && sortedAllProducts.length && sortedAllProducts.map((item, index) => (
            <>
          <Typography data-category={item.category} className='catName'>{item.category}</Typography>
          <Grid container  spacing={2}>
            {item.products.map((product, index) => (
              <Grid item xs={6} sm={6} md={4} lg={4} xl={4} className="image-hover-effect" key={index} data-product-index={index}>
                {product.type === 'veg' ? (
                  <>
                    <Grid container justifyContent='space-between'  sx={{maxWidth:'70px',padding:'10px'}}>
                      <Grid item>
                        <FiberManualRecordIcon sx={{fontSize:'10px' , border:'1px solid #2ebd59' , padding:'2px' , color:'#2ebd59'}} />
                      </Grid>
                      <Grid item>
                        <Typography sx={{fontSize:'14px'}}>Veg</Typography>
                      </Grid>
                    </Grid>
                  </>
                ):(
                  <Grid container justifyContent='space-between'  sx={{maxWidth:'110px',padding:'10px'}}>
                  <Grid item>
                    <FiberManualRecordIcon sx={{fontSize:'10px' , border:'1px solid #d0021b' , padding:'2px' , color:'#d0021b'}} />
                  </Grid>
                  <Grid item>
                    <Typography sx={{fontSize:'14px'}}>Non - Veg</Typography>
                  </Grid>
                </Grid>
                )}
                <Card sx={{ maxWidth: 345 }}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      image={product.img  || "https://d3gy1em549lxx2.cloudfront.net/8f885d75-d642-4db3-89ce-04cfc428db37.jpg"}
                      alt="green iguana"
                      className='prodimg'
                    />
                    <CardContent >
                      <Typography  variant="body1" color="text.secondary">
                       {product.name}
                      </Typography>
                      <Grid container justifyContent='space-between' sx={{padding:'10px 0px'}}>
                        <Grid item>
                          <Grid container direction='column' >
                            <Grid item sx={{display:'flex' , flexDirection:'row'}}>
                              <Typography sx={{fontWeight:'bold' , fontSize:'1.2rem'}}>₹{product.price || '100'}</Typography>
                              {/* <Typography sx={{fontSize:'1.2rem', textDecoration:'line-through' , paddingLeft:'10px'}}>₹{product.org || '-'}</Typography> */}
                            </Grid>
                            {/* <Grid item>
                              <Typography>{product.offer}% OFF</Typography>
                            </Grid> */}
                          </Grid>
                        </Grid>
                        {/* <Grid item>
                           <button className="button-5">Add</button>
                        </Grid> */}
                        <Grid item>
                          {/* <Typography>{product.offer}% OFF</Typography> */}
                        </Grid>
                      </Grid>

                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
          </>
          ))}
        </Paper>
      </Grid>
    </Grid>
    
  );
};

export default ProductPage;